import { createSelector } from 'reselect'
var formatter = new Intl.NumberFormat("es-AR", { style: "currency", currency: "ARS" });
const mapTransactionDetailLines = createSelector(
    detailLines => detailLines,
    detailLines =>
    detailLines.map(detailLine => ({
            id: detailLine.id,
            description: detailLine.description,
            amount_gross: formatter.format(detailLine.amount_gross)
        }))
)

export default mapTransactionDetailLines