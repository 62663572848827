import { createSelector } from 'reselect'

const whatMonth = (date) => {
  var dateformat = new Date(date);
  return dateformat.getMonth() + 1
}

//Mapeo expensas para colocarle el mes en forma de numero y darle un orden
let mapExpensas = createSelector(  
  expenses => expenses,
  expenses => 
  expenses.homeExpenses ? (expenses.homeExpenses.map(expensa => ({
    month: whatMonth(expensa.expenses_header.period_start_date),
    expenses_header: expensa.expenses_header,
    total_cobrado: expensa.total_cobrado,
    total_pendiente_cobrar: expensa.total_pendiente_cobrar,
    total_usuario_debito_directo: expensa.total_usuario_debito_directo,
    
  })) ) : (expenses)
)
export default mapExpensas
