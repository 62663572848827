import * as actionTypes from '../actions/actionTypes'
import { updateObject } from '../../utils/utility'
import { actions } from 'react-table'

const initialState = {
  OrdersList: [],
  totalOrders: 0,
  loadingOrdersList: false,
  errorOrdersList: false,

  fetchHomeExpensesSuccess: false,
  fetchHomeExpensesFail: false,
  loadingfetchHomeExpenses: false,
  homeExpenses: null,

  fetchExpensesClienteSuccess: false,
  fetchExpensesClienteFail: false,
  loadingfetchExpensesCliente: false,
  ExpensesCliente: null,

  fetchOrdersClienteSuccess: false,
  fetchOrdersClienteFail: false,
  loadingfetchOrdersCliente: false,
  OrdersCliente: null,

  fetchUploadFileSuccess: false,
  fetchUploadFileFail: false,
  loadingFetchUploadFile: true,
  uploadFile: null,

  fetchDetailNeighbourhoodSuccess: false,
  fetchDetailNeighbourhoodFail: false,
  loadingFetchDetailNeighbourhood: false,
  DetailNeighbourhood: null,


  loadingGenerateAutomaticExpensas: false,
  errorGenerateAutomaticExpensas: false,
  errorGenerateExpensasAutomaticMessage: false,
  loadingAutomaticExpense: false,
  descargaFile: null,
  expensas: null,

  loadingGenerateExpensas: false,
  errorGenerateExpensas: false,
  errorGenerateExpensasMessage: false,

  fileDetailClient: null,
  errorfileDetailClient: false,
  errorfileDetailClientMessage: false,
  loadingfileDetailClient: false,

  pageExpenses: 0,

  page: 0,
  year: (new Date).getFullYear(),
  hasMoreTransactions: true,
  eventTake: 10
}

const FetchHomeExpensesSuccess = (state, action) => {
  return updateObject(state, {
    loadingfetchHomeExpenses: false,
    fetchHomeExpensesSuccess: true,
    fetchHomeExpensesFail: false,
    homeExpenses: action.data
  })
}

const FetchHomeExpensesFail = (state, error) => {
  return updateObject(state, {
    loadingfetchHomeExpenses: false,
    fetchHomeExpensesSuccess: false,
    fetchHomeExpensesFail: error,
    homeExpenses: null
  })
}

const FetchHomeExpensesStart = state => {
  return updateObject(state, {
    loadingfetchHomeExpenses: true,
    fetchHomeExpensesSuccess: false,
    fetchHomeExpensesFail: false,
    homeExpenses: null
  })
}
const setPageExpenseList = (state, action) => {
  return updateObject(state, {
    pageExpenses: action.page
  })
}


const FetchExpensesClienteSuccess = (state, action) => {
  return updateObject(state, {
    fetchExpensesClienteSuccess: true,
    fetchExpensesClienteFail: false,
    loadingfetchExpensesCliente: false,
    ExpensesCliente: action.data
  })
}

const FetchExpensesClienteFail = (state, error) => {
  return updateObject(state, {
    fetchExpensesClienteSuccess: false,
    fetchExpensesClienteFail: error,
    loadingfetchExpensesCliente: false,
    ExpensesCliente: null,
  })
}

const FetchExpensesClienteStart = state => {
  return updateObject(state, {
    fetchExpensesClienteSuccess: false,
    fetchExpensesClienteFail: false,
    loadingfetchExpensesCliente: true,
    ExpensesCliente: null,
  })
}

const FetchUploadFileSuccess = (state, action) => {
  return updateObject(state, {
    fetchUploadFileSuccess: true,
    fetchUploadFileFail: false,
    loadingFetchUploadFile: false,
    uploadFile: action.data,
  })
}

const FetchUploadFileFail = (state, error) => {
  return updateObject(state, {
    fetchUploadFileSuccess: false,
    fetchUploadFileFail: true,
    loadingFetchUploadFile: error,
    uploadFile: null,
  })
}

const FetchUploadFileStart = state => {
  return updateObject(state, {
    fetchUploadFileSuccess: false,
    fetchUploadFileFail: false,
    loadingFetchUploadFile: true,
    uploadFile: null,
  })
}

const FetchDetailNeighbourhoodSuccess = (state, action) => {
  return updateObject(state, {
    fetchDetailNeighbourhoodSuccess: true,
    fetchDetailNeighbourhoodFail: false,
    loadingFetchDetailNeighbourhood: false,
    DetailNeighbourhood: action.data
  })
}

const FetchDetailNeighbourhoodFail = (state, error) => {
  return updateObject(state, {
    fetchDetailNeighbourhoodSuccess: false,
    fetchDetailNeighbourhoodFail: error,
    loadingFetchDetailNeighbourhood: false,
    DetailNeighbourhood: null
  })
}

const FetchDetailNeighbourhoodStart = state => {
  return updateObject(state, {
    fetchDetailNeighbourhoodSuccess: false,
    fetchDetailNeighbourhoodFail: false,
    loadingFetchDetailNeighbourhood: true,
    DetailNeighbourhood: null
  })
}



//------------------------------------

const fetchGenerateExpensasSuccess = (state, action) => {
  return updateObject(state, {
    expensas: action.expensas,
    loadingGenerateExpensas: false,
    errorGenerateExpensas: false,
    errorGenerateExpensasMessage: false,
    descargaFile: true,
  })
}

const fetchGenerateExpensasFail = (state, action) => {
  return updateObject(state, {
    loadingGenerateExpensas: false,
    errorGenerateExpensas: true,
    errorGenerateExpensasMessage: action.error,
    expensas: "",
    descargaFile: false,
  })
}

const fetchGenerateExpensasStart = state => {
  return updateObject(state, {
    loadingGenerateExpensas: true,
    descargaFile: true, errorGenerateExpensas: false, errorGenerateExpensasMessage: false
  })
}


//--------------- Generate file expensas for the bank ------------------------
const fetchGenerateAutomaticExpensasSuccess = (state, action) => {
  return updateObject(state, {
    expensas: action.expensas,
    loadingGenerateAutomaticExpensas: false,
    errorGenerateAutomaticExpensas: false,
    errorGenerateAutomaticExpensasMessage: false,
    descargaFile: false,
  })
}

const fetchGenerateAutomaticExpensasFail = (state, action) => {
  return updateObject(state, {
    loadingGenerateAutomaticExpensas: false,
    errorGenerateAutomaticExpensas: true,
    errorGenerateAutomaticExpensasMessage: action.error,
    expensas: "",
    descargaFile: false,
  })
}

const fetchGenerateAutomaticExpensasStart = state => {

  return updateObject(state, { descargaFile: false, loadingGenerateAutomaticExpensas: true, errorGenerateAutomaticExpensas: false, errorGenerateExpensasAutomaticMessage: false })
}

const fetchGenerateAutomaticExpensasReset = state => {
  console.log('reseteando --- fetchGenerateAutomaticExpensasReset')
  return updateObject(state, { loadingGenerateAutomaticExpensas: false, expensas: "", descargaFile: false, })
}


//--------------- Get the File detail client ------------------------
const fetchgetFileDetailClientSuccess = (state, action) => {
  return updateObject(state, {
    fileDetailClient: action.fileDetailClient,
    loadingfileDetailClient: false,
    errorfileDetailClient: false,
  })
}

const fetchgetFileDetailClientFail = (state, action) => {
  return updateObject(state, {
    loadingfileDetailClient: false,
    errorfileDetailClient: true,
    errorfileDetailClientMessage: action.errorfileDetailClient
  })
}

const fetchgetFileDetailClientStart = state => {
  return updateObject(state, { loadingfileDetailClient: true, errorfileDetailClient: false })
}
//-------------Get Orders-------------
const setPageOrderList = (state, action) => {
  return updateObject(state, {
    page: action.page
  })
}

const setYearOrderList = (state, action) => {
  return updateObject(state, {
    year: action.year
  })
}

const fetchOrderListSuccess = (state, action) => {
  return updateObject(state, {
    OrdersList: action.orders.values.results,
    totalOrders: action.orders.values.total_results,
    loadingOrdersList: false,
    errorOrdersList: false
  });
};

const fetchOrderListFail = (state, action) => {
  return updateObject(state, {
    OrdersList: [],
    loadingOrdersList: false,
    errorOrdersList: action.error.response.data.message
  });
};

const fetchOrderListStart = state => {
  return updateObject(state, {
    OrdersList: [],
    loadingOrdersList: true
  });
};




const reducer = (state = initialState, action) => {
  switch (action.type) {
    //FETCH HOME
    case actionTypes.FETCH_GET_HOME_EXPENSES_SUCCESS:
      return FetchHomeExpensesSuccess(state, action)
    case actionTypes.FETCH_GET_HOME_EXPENSES_START:
      return FetchHomeExpensesStart(state, action)
    case actionTypes.FETCH_GET_HOME_EXPENSES_FAIL:
      return FetchHomeExpensesFail(state, action)
    //FETCH SET DETAIL PAGE
    case actionTypes.SET_DETAIL_PAGE:
      return setPageExpenseList(state, action)
    //FETCH  CLIENT
    case actionTypes.FETCH_GET_EXPENSES_CLIENTE_SUCCESS:
      return FetchExpensesClienteSuccess(state, action)
    case actionTypes.FETCH_GET_EXPENSES_CLIENTE_START:
      return FetchExpensesClienteStart(state, action)
    case actionTypes.FETCH_GET_EXPENSES_CLIENTE_FAIL:
      return FetchExpensesClienteFail(state, action)
    //FETCH FILE
    case actionTypes.FETCH_POST_UPLOAD_FILE_SUCCESS:
      return FetchUploadFileSuccess(state, action)
    case actionTypes.FETCH_POST_UPLOAD_FILE_START:
      return FetchUploadFileStart(state, action)
    case actionTypes.FETCH_POST_UPLOAD_FILE_FAIL:
      return FetchUploadFileFail(state, action)
    //FETCH Neighbourhood
    case actionTypes.FETCH_AUTOMATIC_GENERATE_EXPENSES_START:
      return FetchDetailNeighbourhoodSuccess(state, action)
    case actionTypes.FETCH_AUTOMATIC_GENERATE_EXPENSES_START:
      return FetchDetailNeighbourhoodStart(state, action)
    case actionTypes.FETCH_GET_FILE_DETAIL_NEIGHBOURHOOD_FAIL:
      return FetchDetailNeighbourhoodFail(state, action)

    //FETCH GENERATE
    case actionTypes.FETCH_AUTOMATIC_GENERATE_EXPENSES_SUCCESS:
      return fetchGenerateAutomaticExpensasSuccess(state, action)
    case actionTypes.FETCH_AUTOMATIC_GENERATE_EXPENSES_START:
      return fetchGenerateAutomaticExpensasStart(state, action)
    case actionTypes.FETCH_AUTOMATIC_GENERATE_EXPENSES_FAIL:
      return fetchGenerateAutomaticExpensasFail(state, action)
    case actionTypes.FETCH_AUTOMATIC_GENERATE_EXPENSES_RESET:
      return fetchGenerateAutomaticExpensasReset(state, action)

    case actionTypes.FETCH_GET_FILE_DETAIL_CLIENT_SUCCESS:
      return fetchgetFileDetailClientSuccess(state, action)
    case actionTypes.FETCH_GET_FILE_DETAIL_CLIENT_FAIL:
      return fetchgetFileDetailClientFail(state, action)
    case actionTypes.FETCH_GET_FILE_DETAIL_CLIENT_START:
      return fetchgetFileDetailClientStart(state, action)


    case actionTypes.FETCH_GENERATE_EXPENSES_SUCCESS:
      return fetchGenerateExpensasSuccess(state, action)
    case actionTypes.FETCH_GENERATE_EXPENSES_FAIL:
      return fetchGenerateExpensasFail(state, action)
    case actionTypes.FETCH_GENERATE_EXPENSES_START:
      return fetchGenerateExpensasStart(state, action)

    //FETCH ORDERS
    case actionTypes.FETCH_ORDER_LIST_SUCCESS:
      return fetchOrderListSuccess(state, action)
    case actionTypes.FETCH_ORDER_LIST_FAIL:
      return fetchOrderListFail(state, action)
    case actionTypes.FETCH_ORDER_LIST_START:
      return fetchOrderListStart(state, action)


    case actionTypes.SET_ORDER_PAGE:
      return setPageOrderList(state, action)
    case actionTypes.SET_ORDER_YEAR:
      return setYearOrderList(state, action)




    default:
      return state
  }
}

export default reducer