import React from 'react';
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import compose from 'recompose/compose'
import Modal from '../../../components/Modal';
import ModalProcessingOrder from '../ModalProcessingOrder';
import Button from '../../../components/Button';
// import Title  from '../../../components/Title';
import PageLoader from '../../../components/Loader';
import { getFormattedDateYear } from '../../../utils/dateParser'
import DatePickerStyled from '../../../components/DatePickerStyled'

import { Formik, Field } from 'formik'
import * as Yup from 'yup'

import {
    fetchGenerateAutomaticExpensas,
    fetchGenerateAutomaticExpensesReset,
    fetchGenerateExpensas
} from '../../../store/actions/expenses.actions'
import { setNeighbourhood } from '../../../store/actions/userInfo.actions';
import Select from 'react-select';

import {
    FormContainer,
    Row,
    InputLabel,
    InputWrapper,
    ErrorLabel,
    ButtonContent,
    StyledButton,
    CrossImage,
    LabelFecha,
    ExpensasList,
    ListItem,
} from './styled'
class GenerateExpense extends React.Component {

    state = {
        user: JSON.parse(localStorage.getItem('user')),
        filter: '',
        modalProcessingOrder: false,
        newExpensa: false,
        isDetailItemOpen: false,
        check: false,
        body: {
            'PaymentMethod': 'APP_MB',
            'DateFrom': '',
        }
    }

    getInitialValues = () => ({

        Date: '',
    })

    handleCheck() {
        this.setState({ check: !this.state.check })
    }
    getSignUpSchema = () =>
        Yup.object().shape({
            Date: Yup.string().required('El campo mes actual es requerido'),
        })

    handleClosePopUp() {
        this.props.confirmar();
        this.props.onClose(false);
    }

    getFormContent = ({ handleSubmit, values, setFieldValue, errors, }) => (
        <FormContainer onSubmit={handleSubmit}>

            <Row> <InputLabel>{'Generar expensas adheridas al debito'}</InputLabel> </Row>
            <Row margin={"25"}>
                <InputWrapper width={28} margin={'0px 5px'}>
                    <LabelFecha>{'Mes a cobrar :'}</LabelFecha>
                    <DatePickerStyled
                        selected={values.Date}
                        startDate={values.startDate}
                        endDate={values.endDate}
                        selectsStart
                        onChange={Date => setFieldValue('Date', Date)}
                        minDate={0}
                        placeholderText="Fecha"
                        dateFormat="MMMM"
                        showMonthYearPicker
                        locale="es"
                    />
                </InputWrapper>
            </Row>

            {errors.Date && <ErrorLabel>{errors.Date}</ErrorLabel>}
            <>
                <ButtonContent>
                    <Button medium type='submit' > Cobrar </Button>
                </ButtonContent>
            </>
        </FormContainer>

    )

    componentDidMount() {
        this.props.fetchGenerateAutomaticExpensesReset();
    }

    componentDidUpdate() {
        if (!this.props.expensas.loadingGenerateExpensas && this.state.check === true && this.props.expensas.descargaFile) {
            this.downloadBlob()
        }
    }

    handleSubmit = data => {
        let newBody = this.state.body;
        newBody.DateFrom = getFormattedDateYear(data.Date);
        if (this.state.check === true) {
            this.props.fetchGenerateExpensas(newBody)
        } else {
            this.props.fetchGenerateAutomaticExpensas(newBody)
        }
        this.handleClosePopUp()

    }

    downloadBlob() {
        if (this.props.expensas.expensas.file_result.length > 0) {

            var binaryData = [];
            binaryData.push(this.props.expensas.expensas.file_result[0].fileText);

            const a = document.createElement('a');
            document.body.appendChild(a);
            const url = window.URL.createObjectURL(new Blob(binaryData, { type: "text/plain" }))
            a.href = url;
            a.download = "expensas generadas";
            a.click();
            setTimeout(() => {
                window.URL.revokeObjectURL(url);
                document.body.removeChild(a);
            }, 0)
        }
    }

    render() {
        return (
            <>
                <Modal onClose={() => this.props.onClose()} height={'auto'} width={'50%'} >
                    <Formik
                        initialValues={this.getInitialValues()}
                        validateOnChange={false}
                        onSubmit={this.handleSubmit}
                        validationSchema={this.getSignUpSchema()}
                        render={e => this.getFormContent(e)}
                    />
                </Modal>
            </>
        );
    }
}

const mapStateToProps = ({
    userInfo: { neighbourhoods, username },
    expenses
}) => ({
    neighbourhoods,
    username,
    expensas: expenses
})

const mapDispatchToProps = dispatch =>
    bindActionCreators(
        {
            fetchGenerateAutomaticExpensas,
            fetchGenerateAutomaticExpensesReset, fetchGenerateExpensas
        },
        dispatch
    )

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(
    compose(
    )(GenerateExpense)
)
