import React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import Login from './containers/Authentication';
import Toolbar from './containers/Layout/toolbar';
import BurgerMenu from './layouts/toolbar/BurgerMenu';
import styled from 'styled-components';

import routeList from './routeList';
import Error from './containers/ErrorRoles';

const ContainerEmpty = styled.div`
  display: flex;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
`

const AppRoute = ({ component: Component, layout: Layout, ...rest }) => (
  <Route
    {...rest}
    render={props => (
      <Layout>
        <Component {...props} />
      </Layout>
    )}
  />
)

const ToolbarLayout = props => <Toolbar>{props.children}</Toolbar>

const mobileLayout = props => <BurgerMenu>{props.children}</BurgerMenu>

const NoneLayout = props => <ContainerEmpty>{props.children}</ContainerEmpty>

const Routes = props => {

  let activeLayout = ToolbarLayout;
  if (props.responsive === false) {
    let routes = (
      <Switch>
        <AppRoute
          path="/login"
          exact
          component={Login}
          props={props.childProps}
          layout={NoneLayout} />
        <AppRoute
          path="/forgot-password"
          exact
          component={Login}
          props={props.childProps}
        />
        <Redirect to="/login" />
      </Switch>
    )
    if (props.isAuth) {
      const authRoutes = routeList.filter((route) => props.isAuth.menusAuth.some(menu => menu.name === route.title));
      if(authRoutes.length == 0){
        routes = (
          <Switch> 
            <AppRoute
              path="/error"
              exact
              component={Error}
              props={props.childProps}
              layout={NoneLayout} 
            />
             <Redirect to={ '/error'} />
          </Switch>
        )
      }else{
        routes = (
          <Switch>
  
          {authRoutes.map((route, i) => {
            return(
              <AppRoute
                path={route.href}
                exact
                component={route.component}
                props={props.childProps}
                layout={activeLayout}
                key={i}
              />
            )
          })}
            <Redirect to={authRoutes.length > 0 ? authRoutes[0].href : '/error'} />
          </Switch>
        )
      }
     
    }
    return routes
  } else {
    //MOBILE DESIGN
    let routes = (
      <Switch>
        <AppRoute
          path="/login"
          exact
          component={Login}
          props={props.childProps}
          layout={NoneLayout} />
        <AppRoute
          path="/forgot-password"
          exact
          component={Login}
          props={props.childProps}
        />
        <Redirect to="/login" />
      </Switch>
    )

    if (props.isAuth) {
      const authRoutes = routeList.filter((route) => props.isAuth.menusAuth.some(menu => menu.name === route.title));

      if(authRoutes.length == 0){
        routes = (
          <Switch> 
            <AppRoute
              path="/error"
              exact
              component={Error}
              props={props.childProps}
              layout={NoneLayout} 
            />
             <Redirect to={ '/error'} />
          </Switch>
        )
      }else{
      routes = (
        <Switch>
          {authRoutes.map((route, i) => {
            return(
              <AppRoute
                path={route.href}
                exact
                component={route.component}
                props={props.childProps}
                layout={mobileLayout}
                key={i}
              />
            )
          })}
          {/* <Redirect to={authRoutes.length > 0 ? authRoutes[0].href : '/dashboard'} /> */}
          <Redirect to={'/dashboard'} />
        </Switch>
      )}
    }
    return routes
  }
}

export default Routes