import React, { useEffect } from 'react'
import { Formik, Form } from 'formik'
import * as Yup from 'yup'
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import CrossIcon from '../../../../assets/icons/close.svg'
import CircularProgress from '@material-ui/core/CircularProgress'
import Input from '../../../../components/Input'
import Modal from '../../../../components/Modal'
import TextArea from '../../../../components/TextArea'
import Button from '../../../../components/Button';
import Spinner from 'react-spinner-material';
import Select from 'react-select'
import FileInput from '../../../../components/InputFile'
import FileImageInput from '../../../../components/FileImageInput'
import NoFilePdf from '../../../../assets/icons/upload-pdf.svg'
import NoFile from '../../../../assets/icons/plus.png'
import { fetchBankAccounts, fetchBankAccountsReset } from '../../../../store/actions/paymentClients.actions';
import {
  FormContainer,
  Row,
  InputWrapper,
  InputLabel,
  ErrorLabel,
  ButtonWrapper,
  StyledButton,
  CrossImage,
  FlexWrapper,
  SpinerWrapper,
  Title,
  ResetIcon,
  ProgressBarWrapper
} from './styled'

const mapStateToProps = state => {
  return {
       bankAccounts: state.paymentClients.bankAccounts,
  }
};

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    { fetchBankAccounts, fetchBankAccountsReset },
    dispatch
  )


function ClientModal(props) {

  const { paymentClient, isReadOnly, isSubmitting, shops, bankAccounts, loadingFetchBankAccounts, fetchBankAccountsSuccess, errorFetchBankAccounts } = props;

  const getShopsOptions = () => {
    return props.shops.map(n => ({ label: n.name, value: n.shop_store_guid }))
  }

  const getBankAccountsOptions = () => {
    //  if (bankAccounts != null && bankAccounts !== [])
     if (bankAccounts != null && bankAccounts.length > 0 )
      return bankAccounts.map(n => ({ label: formatLabel(n), value: n.Id }))
  }
  const formatLabel = (bankAccount) => {
    return bankAccount.ClientAppUserName + ' - CUIT: ' + bankAccount.HolderCuit + ' - Moneda: ' + bankAccount.Currency

  }

  const fetchBankAccountsByShop = async (setFieldValue, option) => {
    setFieldValue('neighbourhoodGuid', option.value);
    await props.fetchBankAccounts(option.value);
    setFieldValue('bank_account_id', '');
  }


  const getInitialValues = () => {
    return ({
      id: paymentClient ? paymentClient.id : 0,
      description: paymentClient ? paymentClient.description : '',
      name: paymentClient ? paymentClient.name : '',
      logo_url: paymentClient ? paymentClient.logo_url : '',
      notification_url: paymentClient ? paymentClient.notification_url : '',
      bank_account_id: paymentClient ? paymentClient.bank_account_id : '',
      neighbourhoodGuid: paymentClient ? paymentClient.neighbourhoodGuid : '',
      token: paymentClient ? paymentClient.token : '',
      isEdit: paymentClient ? true : false
    })
  };


  const getSignUpSchema = () =>
    Yup.lazy(values =>
      Yup.object().shape({
        description: Yup.string().required('Campo Obligatorio'),
        name: Yup.string().required('Campo Obligatorio'),
        logo_url: Yup.string().required('Seleccione un logo'),
        notification_url: Yup.string().required('Campo Obligatorio'),
        bank_account_id: Yup.string().required('Campo Obligatorio'),
        neighbourhoodGuid: Yup.string().required('Campo Obligatorio')
      })
    );


  const getFormContent = ({ handleSubmit, values, setFieldValue, setValues, resetForm, errors }) => {

    const shopOptions = getShopsOptions();
    const bankAccountOptions = getBankAccountsOptions();
    const selectedBankAccount = bankAccountOptions && bankAccountOptions.length > 0 && values.bank_account_id ? bankAccountOptions.find(option => option.value === values.bank_account_id) : ''

    return (
      <Form onSubmit={handleSubmit}>
        <FlexWrapper>
          <FlexWrapper width={70}>
            <InputWrapper>
              <InputLabel>{'Nombre'}</InputLabel>
              <Input
                fullwidth={true}
                name={'nameInput'}
                label={'nameInput'}
                placeholder={'Ingrese un titulo...'}
                value={values.name}
                error={errors.name}
                onChange={({ target: value }) => setFieldValue('name', value.value)}
                margin="normal"
                disabled={props.isReadOnly ? props.isReadOnly : false}
              />
              {errors.name && <ErrorLabel>{errors.name}</ErrorLabel>}
            </InputWrapper>
            <InputWrapper>
              <InputLabel>{'Descripción'}</InputLabel>
              <TextArea
                height={'7.5em'}
                name={'descriptionInput'}
                label={'descriptionInput'}
                placeholder={'Ingrese una descripción...'}
                value={values.description}
                error={errors.description}
                onChange={({ target: value }) => setFieldValue('description', value.value)}
                margin="normal"
                disabled={props.isReadOnly ? props.isReadOnly : false}
              ></TextArea>
              {errors.description && <ErrorLabel>{errors.description}</ErrorLabel>}
            </InputWrapper>
          </FlexWrapper>
          <FlexWrapper width={30}>
            <InputWrapper >
              <InputLabel>{'Logo'}</InputLabel>
              <FileImageInput
                width="100%"
                value={values.logo_url}
                onChange={logo_url => setFieldValue('logo_url', logo_url)}
                disabled={props.isReadOnly ? props.isReadOnly : false} />
              {errors.logo_url && <ErrorLabel textAlign="center">{errors.logo_url}</ErrorLabel>}
            </InputWrapper>
          </FlexWrapper>
          <FlexWrapper>
            <InputWrapper>
              <InputLabel>{'Shop'}</InputLabel>
              <Select
                fullwidth={true}
                name={'shopGuidInput'}
                label={'shopGuidInput'}
                value={shopOptions ? shopOptions.find(option => option.value === values.neighbourhoodGuid) : ''}
                error={errors.neighbourhoodGuid}
                placeholder="Seleccione un shop..."
                isDisabled={props.isReadOnly ? props.isReadOnly : false}
                options={shopOptions}
                onChange={option => fetchBankAccountsByShop(setFieldValue, option, values)}
                margin="normal"
              />
              {errors.neighbourhoodGuid && <ErrorLabel>{errors.neighbourhoodGuid}</ErrorLabel>}
            </InputWrapper>
            <InputWrapper>
              <InputLabel>{'Cuenta Bancaria'}</InputLabel>
              <Select
                fullwidth={true}
                name={'bankAccountIdInput'}
                label={'bankAccountIdInput'}
                value={selectedBankAccount}
                // value={values.bank_account_id} 
                // defaultValue={setBankAccountField(setFieldValue)}
                error={errors.bank_account_id}
                placeholder="Seleccione una cuenta bancaria..."
                options={bankAccountOptions}
                isDisabled={props.isReadOnly ? true : false}
                isLoading={props.isLoading}
                enableReinitialize={false}
                onChange={option => setFieldValue('bank_account_id', option.value)}
                margin="normal"
              />
              {errors.bank_account_id && <ErrorLabel>{errors.bank_account_id}</ErrorLabel>}
            </InputWrapper>
            <InputWrapper>
              <InputLabel>{'Notificación URL'}</InputLabel>
              <Input
                fullwidth={true}
                name={'notificationUrlInput'}
                label={'notificationUrlInput'}
                placeholder={'Ingrese una URL...'}
                value={values.notification_url}
                error={errors.notification_url}
                onChange={({ target: value }) => setFieldValue('notification_url', value.value)}
                margin="normal"
                disabled={props.isReadOnly ? props.isReadOnly : false}
              />
              {errors.notification_url && <ErrorLabel>{errors.notification_url}</ErrorLabel>}
            </InputWrapper>
          </FlexWrapper>
          {isReadOnly ?
            <InputWrapper>
              <InputLabel>{'Token'}</InputLabel>
              <Input
                fullwidth={true}
                name={'token'}
                label={'token'}
                value={values.token}
                error={errors.token}
                onChange={({ target: value }) => setFieldValue('token', value.value)}
                margin="normal"
                disabled={true}
              />
            </InputWrapper> : null
          }
        </FlexWrapper>
        {!isReadOnly ? <FlexWrapper>
          <ButtonWrapper>
            <Button type={'submit'} loading={isSubmitting}>
              Publicar
            </Button>
          </ButtonWrapper>
        </FlexWrapper> : null}
      </Form>
    )
  }

  const form = (() => {
    if (props.isLoading) {
      return (<SpinerWrapper><Spinner /></SpinerWrapper>);
    }
    return (<Formik
      initialValues={getInitialValues()}
      validateOnChange={false}
      validationSchema={getSignUpSchema()}
      onSubmit={props.onSubmit}
      render={e => getFormContent(e)}
    />);
  });

  return (
    <Modal title={props.title} onClose={props.onClose} height={'min-height'} >
      {form()}
    </Modal>
  )
}

// export default ClientModal
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ClientModal)