/* eslint-disable react/prop-types */
import React, { useEffect, useState, useRef } from "react";
import Input from "../../Input";
import CloseImg from "../../../assets/icons/close-white.svg";
import { Formik } from "formik";
import * as Yup from "yup";
import { isTimeRangeValid } from "../../../utils/timeFormatter";
import Modal from "../ModalReservas";
import DetailTitle from "../DetailTitle";
import CrossIcon from "../../../assets/icons/close.svg";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
// import { deleteReservationById, generateBooking } from 'store/actions/reservas.actions';
import compose from "recompose/compose";
import withWidth from "@material-ui/core/withWidth";
import ModalMessage from "../../ModalMessage";
import { getFormattedDateYear } from "../../../utils/dateParser";
import ModalDelete from "../../../components/ModalDelete";
import theme from "../../../config/theme";
import BookingDetailModal from "../BookingDetailModal";
import MultipleBookingDetailModal from "../MultipleBookingDetailModal";

import {
  cancelBookingReset,
  cancelBooking,
  fetchSchedulesCalendar
} from "../../../store/actions/bookings.actions";

import {
  DetailContainer,
  FormContainer,
  Title,
  SubTitle,
  Name,
  CancelButton,
  Row,
  TitleModal,
  Column,
  SaveButton,
  Close,
  CrossImage,
  ErrorLabel,
  ButtonWrapper,
  ButtonWrapperBooking,
  StyledLabelBooking,
  StyledButtonBooking,
  StyledButton,
  Ahref,
  AhrefWrapper
} from "./styled";

const motivosData = [
  { label: "Mal clima", value: "0" },
  { label: "Evacuacion", value: "1" },
  { label: "Falta abonar", value: "3" }
];

const mapStateToProps = state => {
  return {
    cancelBookingSuccess: state.bookings.cancelBookingSuccess,
    errorCancelBooking: state.bookings.errorCancelBooking
    // loadingCancelBooking
    // userInfo,
    // loadingDeleteAPI: reservas.loadingDeleteReservation,
    // deletedReservation: reservas.deletedReservation,
    // fecthDeleteReservationIsDone: reservas.fecthDeleteReservationIsDone,
    // // roles: mapRoles(profile.roles),
    // registeredBooking: (reservas != null && reservas.registeredBooking != null) ? reservas.registeredBooking : []
  };
};

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      // deleteReservationById,
      // generateBooking,
      // fetchActiveTimes,
      // getFormattedDateYear
      fetchSchedulesCalendar,
      cancelBooking,
      cancelBookingReset
    },
    dispatch
  );

function DetailModal(props) {
  // state = {
  //   price: "",
  //   modalDeleteReserva: false,
  //   modalGenerateReserva: false,
  //   modalCancelBooking: false,
  //   NameBooking: null,
  //   SurnameBooking: null,
  //   Reason: null,
  //   Observations: null,
  //   activeTimeId: null,
  //   payment: null,
  //   nameError: "",
  //   surnameError: ""
  // };

  const [modalGenerateReserva, setModalGenerateReserva] = useState(false);
  const [modalDeleteReserva, setModalDeleteReserva] = useState(false);
  const [modalCancelBooking, setModalCancelBooking] = useState(false);
  const [NameBooking, setNameBooking] = useState(null);
  const [SurnameBooking, setSurnameBooking] = useState(null);
  const [Observations, setObservations] = useState(null);
  const [Reason, setReason] = useState(null);
  const [surnameError, setSurnameError] = useState("");
  const [nameError, setNameError] = useState("");
  const [deleteValidation, setDeleteValidation] = useState(false);
  const [modalMultipleBookingDetail, setMultipleBookingDetail] = useState(false);

  let hasMultipleBookings = props.originalData.available_places > 1;
  // const [Reason, setReason] = useState(null);

  const checkData = () => {
    if (!hasErrors()) {
      handleGenerarReserva();
    }
  };

  const hasErrors = () => {
    let hasError = false;
    if (NameBooking === null) {
      // setState({ nameError: "Debe ingresar un nombre" });
      setNameError("Debe ingresar un nombre");
      hasError = true;
    }
    if (SurnameBooking === null) {
      // setState({ surnameError: "Debe ingresar un apellido" });
      setSurnameError("Debe ingresar un apellido");
      hasError = true;
    }
    return hasError;
  };

  const toggleGenerateReserva = () => {
    // setState({ modalGenerateReserva: !.modalGenerateReserva });
    setModalGenerateReserva(!modalGenerateReserva);
  };

  const toggleDeleteReserva = () => {
    // setState({ modalDeleteReserva: !.modalDeleteReserva });
    setModalDeleteReserva(!modalDeleteReserva);
  };

  const toggleCancelBooking = () => {
    props.cancelBookingReset();
    // setState({ modalCancelBooking: !.modalCancelBooking });
    setModalCancelBooking(!modalCancelBooking);
  };

  const toggleMultipleBookingDetail = () => {
    setMultipleBookingDetail(!modalMultipleBookingDetail);
  };

  const toggleCancelBooking2 = () => {
    props.cancelBookingReset();
    // setState({ modalCancelBooking: !.modalCancelBooking });
    setModalCancelBooking(!modalCancelBooking);

    if (props.cancelBookingSuccess) {
      props.fetchSchedulesCalendar(
        props.timeRange.originalData.space_id,
        props.selectedCalendarDateTime
      );
    }
  };

  const handleDeleteReserva = async idReserva => {
    await props.deleteReservationById(idReserva);
    props.cerrarModalDetalle();
  };
  const handleNameBooking = data => {
    // setState({ NameBooking: data.target.value });
    setNameBooking(data.target.value);
  };
  const handleSurnameBooking = data => {
    // setState({ SurnameBooking: data.target.value });
    setSurnameBooking(data.target.value);
  };
  const handleObservations = data => {
    // setState({ Observations: data.target.value });
    setObservations(data.target.value);
  };
  const handleReason = data => {
    // setState({ Reason: data.target.value });
    setReason(data.target.value);
  };

  const handleGenerarReserva = async () => {
    const formattedBody = {
      start_date: props.date,
      active_time_id: props.timeRange.id,
      // attendees: [],
      // payment:[],
      Reason: Reason,
      Observations: Observations,
      NameBooking: NameBooking,
      SurnameBooking: SurnameBooking,
      IsAdminBooking: true
    };
    await props.generateBooking(formattedBody);
    props.cerrarModalDetalle();
    props.fetchActiveTimes(props.selectedSpace, getFormattedDateYear(props.startDate));
  };

  const renderDetail = () => (
    <>
      {!modalCancelBooking ? (
        hasMultipleBookings ? (
          <MultipleBookingDetailModal
            cancelFunc={toggleCancelBooking}
            booking={props.booking}
            originalData={props.originalData}
            date={props.date}
            selectedCalendarDateTime={props.selectedCalendarDateTime}
            onClose={props.onClose}
            bookingForm={renderBooking}
          />
        ) : (
          <BookingDetailModal
            cancelFunc={toggleCancelBooking}
            booking={props.booking}
            originalData={props.originalData}
            onClose={props.onClose}
          />
        )
      ) : (
        cancelBookingModal()
      )}
    </>
  );

  const renderBooking = setFieldValue => (
    <>
      {props.userInfo.menusAuth.map(menu => {
        if (menu.code === "RESERV_ENCARGADO_BO") {
          return (
            <Modal top={"25%"} left={"30%"} width={"40vw"} height={"40vh"} display={"grid"}>
              <CrossImage onClick={() => props.onClose(false)} src={CrossIcon} />
              <TitleModal>{"Datos de la reserva"}</TitleModal>
              <FormContainer flexGrow={"3"}>
                <Row>
                  <StyledLabelBooking width="47%">
                    {"Nombre"}
                    <Input
                      value={NameBooking}
                      error={nameError}
                      onChange={handleNameBooking}
                      disabled={false}
                    />
                    {nameError && <ErrorLabel>{nameError}</ErrorLabel>}
                  </StyledLabelBooking>
                  <StyledLabelBooking width="47%">
                    {"Apellido"}
                    <Input
                      value={SurnameBooking}
                      error={surnameError}
                      disabled={false}
                      onChange={handleSurnameBooking}
                    />
                    {surnameError && <ErrorLabel>{surnameError}</ErrorLabel>}
                  </StyledLabelBooking>
                </Row>
                <Row>
                  <StyledLabelBooking>
                    {"Observaciones"}
                    <Input value={Observations} disabled={false} onChange={handleObservations} />
                  </StyledLabelBooking>
                </Row>
                <Row>
                  <StyledLabelBooking>
                    {"Motivo"}
                    <Input value={Reason} disabled={false} onChange={handleReason} />
                  </StyledLabelBooking>
                </Row>
              </FormContainer>
              <ButtonWrapperBooking>
                <StyledButtonBooking onClick={checkData} type={"submit"}>
                  Reservar
                </StyledButtonBooking>
              </ButtonWrapperBooking>
            </Modal>
          );
        }
      })}
    </>
  );

  const getFormContent = ({ handleSubmit, values, setFieldValue, errors }) => (
    <DetailContainer leftPopUp={props.leftPopUp} bottomPopUp={props.bottomPopUp}>
      {/* <FormContainer ref={el => (myFormRef = el)} onSubmit={handleSubmit}> */}
      <FormContainer onSubmit={handleSubmit}>
        <Row>
          <Title>{"Rango inicio:"}</Title>
          <Close src={CloseImg} onClick={props.onClose} />
        </Row>
        <Column>
          <Input
            mask="99:99"
            value={values.from}
            placeholder={"Ingrese HH:MM"}
            onChange={({ target: { value } }) => setFieldValue("from", value)}
            maskChar={null}
            disabled={true}
          >
            {inputProps => <Input {...inputProps} disabled={true} />}
          </Input>
          {errors.from && <ErrorLabel>{errors.from}</ErrorLabel>}
        </Column>
        <Row>
          <Title>{"Rango finalizacion:"}</Title>
        </Row>
        <Column>
          <Input
            mask="99:99"
            value={values.to}
            placeholder={"Ingrese HH:MM"}
            onChange={({ target: { value } }) => setFieldValue("to", value)}
            maskChar={null}
            disabled={props.originalData}
          >
            {inputProps => <Input {...inputProps} disabled={props.originalData} />}
          </Input>
          {errors.to && <ErrorLabel>{errors.to}</ErrorLabel>}
        </Column>

        <Row>
          <Title>{"Valor de la reserva:"}</Title>
        </Row>
        <Column>
          <Input
            // ref={el => (inputRef = el)}
            value={values.price}
            disabled={props.isBooked}
            onChange={({ target: { value } }) => setFieldValue("price", value)}
            placeholder={"Precio"}
            type="number"
          />
          {errors.price && <ErrorLabel>{errors.price}</ErrorLabel>}
        </Column>
        {!props.isBooked && (
          <Row>
            <CancelButton type="button" onClick={deleteRange}>
              {props.originalData ? "Eliminar turno" : "Cancelar"}
            </CancelButton>
            <SaveButton type="submit">{props.originalData ? "Actualizar" : "Guardar"}</SaveButton>
          </Row>
        )}
        {props.isBooked && (
          <ErrorLabel>
            {"No puedes editar un rango que se encuentra reservado actualmente"}
          </ErrorLabel>
        )}
      </FormContainer>
    </DetailContainer>
  );

  const handleSubmit = formData => {
    if (!props.originalData) {
      initializeRange(formData);
    } else if (props.originalData) {
      updateRange(formData);
    }
  };

  const initializeRange = formData => {
    let rangeInitialized = {
      from: props.timeRange.from,
      to: formData.to,
      is_booked: false,
      is_cancelled: false,
      price: formData.price
    };
    props.onRangeCreation(rangeInitialized);
  };

  const updateRange = formData => {
    let rangeUpdated = { ...props.originalData };
    rangeUpdated.price = formData.price;
    props.onRangeChange(rangeUpdated);
  };

  // toggleDeleteValidation = () => setState({ deleteValidation: !.deleteValidation });
  const toggleDeleteValidation = () => setDeleteValidation(!deleteValidation);

  const renderDeleteValidation = () => (
    <Modal>
      <DetailTitle>{"Esta seguro que quiere eliminar"}</DetailTitle>
      <ButtonWrapper>
        <StyledButton onClick={toggleDeleteValidation} type={"submit"}>
          Cancelar
        </StyledButton>
        <StyledButton onClick={deleteRange} type={"submit"}>
          Borrar
        </StyledButton>
      </ButtonWrapper>
    </Modal>
  );

  const renderModalMessageSuccess = (message, callback) => (
    <ModalMessage position="fixed" success={true} onClose={() => callback()}>
      {message}
    </ModalMessage>
  );

  const renderModalMessageFail = (message, callback) => (
    <ModalMessage success={false} onClose={() => callback()}>
      {message}
    </ModalMessage>
  );

  const cancelBookingModal = () => {
    if (props.cancelBookingSuccess) {
      return renderModalMessageSuccess("Se ha cancelado correctamente.", toggleCancelBooking2);
    } else if (props.errorCancelBooking) {
      return renderModalMessageFail("Hubo un error al intentar cancelar", toggleCancelBooking2);
    }
    return (
      <ModalDelete
        title="Cancelar reserva"
        subTitle="¿Estás seguro que querés cancelar la reserva?"
        buttonMessage="Aceptar"
        successMessage="¡Se ha cancelado la reserva!"
        isLoading={false}
        onClose={toggleCancelBooking}
        success={false}
        onSubmit={onCancelBooking}
      ></ModalDelete>
    );
  };

  const onCancelBooking = async () => {
    props.cancelBooking(props.booking.id);
  };

  const deleteRange = () =>
    !props.originalData ? props.onClose() : props.onRangeDelete(props.originalData);

  const render = () => {
    if (props.edit) {
      const rangeRegex = /^(0[0-9]|1[0-9]|2[0-3]):[0,3][0]$/;
      const onlyNumberRegex = /^[0-9]*$/;

      const SignUpSchema = Yup.object().shape({
        from: Yup.string()
          .matches(rangeRegex, "Rango horario invalido")
          .required("Ingrese un rango horario"),
        to: Yup.string()
          .matches(rangeRegex, "Rango horario invalido")
          .test(
            "to-validation",
            "el rango debe ser mayor a el inicio",
            value => value > props.timeRange.from
          )
          .test("range-validation", "El rango ingresado se superpone", value => {
            let isDateValid = true;
            if (!props.originalData) {
              const timeRange = {
                from: props.timeRange.from,
                to: value
              };
              isDateValid = isTimeRangeValid(timeRange, props.dayPos, props.data);
            }
            return isDateValid;
          })
          .required("Ingrese un rango horario"),
        price: Yup.string()
          .matches(onlyNumberRegex, "Precio invalido")
          .required("Ingrese un precio")
      });

      return (
        <Formik
          initialValues={{
            from: props.originalData ? props.originalData.from : props.timeRange.from,
            to: props.originalData ? props.originalData.to : "",
            price: props.price || "0"
          }}
          validateOnChange={false}
          validationSchema={SignUpSchema}
          onSubmit={handleSubmit}
          render={e => getFormContent(e)}
        />
      );
    }
    if (deleteValidation) {
      return renderDeleteValidation();
    }
    if (props.booking) {
      return renderDetail();
    } else {
      // props.userInfo.menusAuth.map(menu=>{
      //   if(menu.code === "TICKETS_ADMIN_BO"){
      return renderBooking();
      //   }
      // })
    }
    return null;
  };

  return render();
}

export default connect(mapStateToProps, mapDispatchToProps)(compose(withWidth())(DetailModal));
