/* eslint-disable react/prop-types */
import React, { useEffect } from "react";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { connect } from "react-redux";
import Input from "../../../../components/Input";
import Button from "../../../../components/Button";
import SideWindow from "../../../../components/SideWindow";
import Select from "react-select";
import Loader from "../../../../components/Loader";
import { fetchAlertMotives } from "../../../../store/actions/alerts.actions";
import { bindActionCreators } from "redux";

import {
  InputWrapper,
  InputLabel,
  ErrorLabel,
  ButtonWrapper,
  FlexWrapper,
  SpinerWrapper,
  TextLegend
} from "./styled";

const mapStateToProps = state => {
  const { alertMotives } = state.alerts;
  return {
    alertMotives: alertMotives
  };
};

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      fetchAlertMotives
    },
    dispatch
  );

function SlideBar(props) {
  const { detailEmergencyPhone, neighbourhoods, alertMotives } = props;

  useEffect(() => {
    if (neighbourhoods.length === 1) props.fetchAlertMotives(neighbourhoods[0].guid, true);
  }, []);

  const getNeighbourhoods = () => {
    return neighbourhoods.map(n => ({ label: n.name, value: n.guid }));
  };

  const getAlertMotivesOptions = () => {
    return alertMotives.map(n => ({ label: n.name, value: n.id, id: n.alert_motive_id }));
  };
  const getNotificationTypesOptions = () => {
    return props.notificationTypes.map(n => ({ label: n.name, value: n.id }));
  };

  const mapNotificationTypes = notificationTypes => {
    return notificationTypes.map(n => ({ label: n.name, value: n.id }));
  };

  const mapAlertMotives = alertMotives => {
    return alertMotives.map(n => ({ label: n.name, value: n.id, id: n.id }));
  };

  const mapNeighbourhoods = neighbourhood => {
    return neighbourhood.map(n => ({ label: n.name, value: n.guid }));
  };

  const mapNeighbourhood = neighbourhood => {
    return { label: neighbourhood.name, value: neighbourhood.guid };
  };

  const onNeighbourhoodChange = async (data, setFieldValue) => {
    setFieldValue("neighbourhood", data);
    await props.fetchAlertMotives(data.value, true);
    setFieldValue("alert_motives", "");
  };

  const getInitialValues = () => {
    return {
      id: detailEmergencyPhone ? detailEmergencyPhone[0].id : 0,
      description: detailEmergencyPhone ? detailEmergencyPhone[0].description : "",
      phone_number: detailEmergencyPhone ? detailEmergencyPhone[0].phone_number : "",
      alert_motives: detailEmergencyPhone
        ? mapAlertMotives(detailEmergencyPhone[0].alert_motives)
        : null,
      notification_types: detailEmergencyPhone
        ? mapNotificationTypes(detailEmergencyPhone[0].notification_types)
        : null,
      neighbourhood: detailEmergencyPhone
        ? mapNeighbourhood(detailEmergencyPhone[0].neighbourhood)
        : neighbourhoods.length === 1
        ? mapNeighbourhoods(neighbourhoods)
        : ""
    };
  };

  const getSignUpSchema = () =>
    Yup.lazy(values =>
      Yup.object().shape({
        description: Yup.string().required("Campo obligatorio"),
        phone_number: Yup.string()
          .required("Campo obligatorio")
          .max(14, "Número invalido"),
        alert_motives: Yup.array().nullable().required("Campo obligatorio"),
        notification_types: Yup.array().nullable().required("Campo obligatorio")
      })
    );

  const getFormContent = ({
    handleSubmit,
    values,
    setFieldValue,
    setValues,
    resetForm,
    errors
  }) => {
    const neighbourhoodsDrop = getNeighbourhoods();
    const alertMotives = getAlertMotivesOptions();
    const notificationTypes = getNotificationTypesOptions();
    return (
      <Form onSubmit={handleSubmit}>
        <FlexWrapper>
          <FlexWrapper width={100}>
            <InputWrapper width={100}>
              <InputLabel>{"Referencia del contacto"}</InputLabel>
              <Input
                fullwidth={true}
                name={"descriptionInput"}
                label={"descriptionInput"}
                placeholder={"Ingrese una referencia"}
                value={values.description}
                error={errors.description}
                onChange={({ target: value }) => setFieldValue("description", value.value)}
                margin="normal"
              />
              {errors.description && <ErrorLabel>{errors.description}</ErrorLabel>}
            </InputWrapper>
            <InputWrapper width={100}>
              <InputLabel>{"Número de teléfono"}</InputLabel>
              <Input
                fullwidth={true}
                name={"phoneNumberInput"}
                label={"phoneNumberInput"}
                placeholder={"Ingrese una número de teléfono"}
                value={values.phone_number}
                error={errors.phone_number}
                onChange={({ target: value }) => setFieldValue("phone_number", value.value)}
                margin="normal"
              />
              {errors.phone_number && <ErrorLabel>{errors.phone_number}</ErrorLabel>}
            </InputWrapper>

            {neighbourhoods.length > 1 ? (
              <InputWrapper width={100}>
                <InputLabel>{"Barrio"}</InputLabel>
                <Select
                  value={values.neighbourhood}
                  onChange={value => onNeighbourhoodChange(value, setFieldValue)}
                  options={neighbourhoodsDrop}
                  placeholder="Seleccione un barrio"
                  isLoading={props.isLoading}
                />
                {errors.neighbourhood && <ErrorLabel>{errors.neighbourhood}</ErrorLabel>}
              </InputWrapper>
            ) : null}

            <InputWrapper width={100}>
              <InputLabel>{"Motivos de alerta"}</InputLabel>
              <Select
                value={values.alert_motives}
                onChange={value => setFieldValue("alert_motives", value)}
                options={alertMotives}
                placeholder="Seleccione un motivo de alerta"
                isLoading={props.isLoading}
                isMulti
              />
              {errors.alert_motives && <ErrorLabel>{errors.alert_motives}</ErrorLabel>}
            </InputWrapper>

            <InputWrapper width={100}>
              <InputLabel>{"Tipo de notificación"}</InputLabel>
              <Select
                value={values.notification_types}
                onChange={value => setFieldValue("notification_types", value)}
                options={notificationTypes}
                placeholder="Seleccione un tipo de notificación"
                isLoading={props.isLoading}
                isMulti
              />
              {errors.notification_types && <ErrorLabel>{errors.notification_types}</ErrorLabel>}
            </InputWrapper>
          </FlexWrapper>
        </FlexWrapper>
        <FlexWrapper>
          <ButtonWrapper>
            <Button type={"submit"} extraLarge={true} loading={false}>
              Guardar cambios
            </Button>
          </ButtonWrapper>
          <ButtonWrapper>
            <Button variant={"primary"} extraLarge={true} onClick={props.onClose}>
              Cancelar
            </Button>
          </ButtonWrapper>
        </FlexWrapper>
      </Form>
    );
  };

  const form = () => {
    if (props.isLoading) {
      return (
        <SpinerWrapper>
          <Loader />
        </SpinerWrapper>
      );
    }
    return (
      <Formik
        initialValues={getInitialValues()}
        validateOnChange={false}
        validationSchema={getSignUpSchema()}
        onSubmit={props.onSubmit}
        render={e => getFormContent(e)}
      />
    );
  };

  return (
    <SideWindow title={props.title} onClose={props.onClose} headerBackgroundColor={"#30735f"}>
      {form()}
    </SideWindow>
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(SlideBar);
