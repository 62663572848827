//AUTH
export const AUTH_START = 'AUTH_START'
export const AUTH_SUCCESS = 'AUTH_SUCCESS'
export const AUTH_FAIL = 'AUTH_FAIL'
export const AUTH_LOGOUT = 'AUTH_LOGOUT'
export const SET_AUTH_REDIRECT_PATH = 'SET_AUTH_REDIRECT_PATH'
export const FETCH_FORGOT_PASSWORD_SUCCESS = 'FETCH_FORGOT_PASSWORD_SUCCESS'
export const FETCH_FORGOT_PASSWORD_FAIL = 'FETCH_FORGOT_PASSWORD_FAIL'
export const FETCH_FORGOT_PASSWORD_START = 'FETCH_FORGOT_PASSWORD_START'
export const FETCH_FORGOT_PASSWORD_RESET = 'FETCH_FORGOT_PASSWORD_RESET'
export const FETCH_FORGOT_PASSWORD_CONFIRMATION_SUCCESS = 'FETCH_FORGOT_PASSWORD_CONFIRMATION_SUCCESS'
export const FETCH_FORGOT_PASSWORD_CONFIRMATION_FAIL = 'FETCH_FORGOT_PASSWORD_CONFIRMATION_FAIL'
export const FETCH_FORGOT_PASSWORD_CONFIRMATION_START = 'FETCH_FORGOT_PASSWORD_CONFIRMATION_START'

export const FETCH_TOKEN_AUTH_LOGIN_SUCCESS = 'FETCH_TOKEN_AUTH_LOGIN_SUCCESS'
export const FETCH_TOKEN_AUTH_LOGIN_FAIL = 'FETCH_TOKEN_AUTH_LOGIN_FAIL'
export const FETCH_TOKEN_AUTH_LOGIN_START = 'FETCH_TOKEN_AUTH_LOGIN_START'

export const FETCH_CONFIG_BRAND_LOGIN_SUCCESS = 'FETCH_CONFIG_BRAND_LOGIN_SUCCESS'
export const FETCH_CONFIG_BRAND_LOGIN_FAIL = 'FETCH_CONFIG_BRAND_LOGIN_FAIL'
export const FETCH_CONFIG_BRAND_LOGIN_START = 'FETCH_CONFIG_BRAND_LOGIN_START'

export const AUTH_REQUIRE_TOKEN = 'AUTH_REQUIRE_TOKEN'


//LAYOUT
export const UPDATE_LAYOUT = '(LAYOUT) UPDATE_THEME_LAYOUT'
export const TOGGLE_SIDENAV = '(LAYOUT) TOGGLE_SIDENAV'
export const SET_SIDENAV_OPEN = '(LAYOUT) SET_SIDENAV_OPEN'
export const TOGGLE_SIDENAV_VARIANT = '(LAYOUT) TOGGLE_SIDENAV_VARIANT'
export const TOGGLE_NOTIFICATIONS = '(LAYOUT) TOGGLE_NOTIFICATIONS'

//THEMES
export const CHANGE_THEME = '(THEME) CHANGE_THEME'
export const CHANGE_SIDENAV_TOOLBAR_BACKGROUND = '(THEME) CHANGE_SIDENAV_TOOLBAR_BACKGROUND'
export const CHANGE_SIDENAV_TOOLBAR_TEXT = '(THEME) CHANGE_SIDENAV_TOOLBAR_TEXT'
export const CHANGE_SIDENAV_PALETTE_TYPE = '(THEME) CHANGE_SIDENAV_PALETTE_TYPE'
export const CHANGE_CONTENT_TOOLBAR_BACKGROUND = '(THEME) CHANGE_CONTENT_TOOLBAR_BACKGROUND'
export const CHANGE_CONTENT_TOOLBAR_TEXT = '(THEME) CHANGE_CONTENT_TOOLBAR_TEXT'
export const CHANGE_CONTENT_PALETTE_TYPE = '(THEME) CHANGE_CONTENT_PALETTE_TYPE'
export const CHANGE_PRIMARY_PALETTE_BACKGROUND = '(THEME) CHANGE_PRIMARY_PALETTE_BACKGROUND'
export const CHANGE_PRIMARY_PALETTE_TEXT = '(THEME) CHANGE_PRIMARY_PALETTE_TEXT'
export const CHANGE_SECONDARY_PALETTE_BACKGROUND = '(THEME) CHANGE_SECONDARY_PALETTE_BACKGROUND'
export const CHANGE_SECONDARY_PALETTE_TEXT = '(THEME) CHANGE_SECONDARY_PALETTE_TEXT'
export const CHANGE_THEME_DIRECTION = '(THEME) CHANGE_TEXT_DIRECTION'

//SECURITYSTAFF
export const FETCH_SECURITYSTAFF_START = 'FETCH_SECURITYSTAFF_START'
export const FETCH_SECURITYSTAFF_SUCCESS = 'FETCH_SECURITYSTAFF_SUCCESS'
export const FETCH_SECURITYSTAFF_FAIL = 'FETCH_SECURITYSTAFF_FAIL'
export const CREATE_SECURITYSTAFF_START = 'CREATE_SECURITYSTAFF_START'
export const CREATE_SECURITYSTAFF_SUCCESS = 'CREATE_SECURITYSTAFF_SUCCESS'
export const CREATE_SECURITYSTAFF_FAIL = 'CREATE_SECURITYSTAFF_FAIL'
export const FILTER_SEARCH_CHANGED = 'FILTER_SEARCH_CHANGED'

//PADRON
export const FETCH_PADRON_START = 'FETCH_PADRON_START'
export const FETCH_PADRON_SUCCESS = 'FETCH_PADRON_SUCCESS'
export const FETCH_PADRON_FAIL = 'FETCH_PADRON_FAIL'
export const CREATE_PROPRIETARY_START = 'CREATE_PROPRIETARY_START'
export const CREATE_PROPRIETARY_SUCCESS = 'CREATE_PROPRIETARY_SUCCESS'
export const CREATE_PROPRIETARY_FAIL = 'CREATE_PROPRIETARY_FAIL'
export const IMPORT_PROPRIETARY_START = 'IMPORT_PROPRIETARY_START'
export const IMPORT_PROPRIETARY_SUCCESS = 'IMPORT_PROPRIETARY_SUCCESS'
export const IMPORT_PROPRIETARY_FAIL = 'IMPORT_PROPRIETARY_FAIL'


export const FETCH_PROPIETARY_FAMILY = 'FETCH_PROPIETARY_FAMILY'
export const FETCH_PROPIETARY_FAMILY_START = 'FETCH_PROPIETARY_FAMILY_START'
export const FETCH_PROPIETARY_FAMILY_SUCCESS = 'FETCH_PROPIETARY_FAMILY_SUCCESS'
export const FETCH_PROPIETARY_FAMILY_FAIL = 'FETCH_PROPIETARY_FAMILY_FAIL'

export const FETCH_PROPIETARY_VEHICLE = 'FETCH_PROPIETARY_VEHICLE'
export const FETCH_PROPIETARY_VEHICLE_START = 'FETCH_PROPIETARY_VEHICLE_START'
export const FETCH_PROPIETARY_VEHICLE_SUCCESS = 'FETCH_PROPIETARY_VEHICLE_SUCCESS'
export const FETCH_PROPIETARY_VEHICLE_FAIL = 'FETCH_PROPIETARY_VEHICLE_FAIL'

//INVITADOS
export const FETCH_GUESTS_START = 'FETCH_GUESTS_START'
export const FETCH_GUESTS_SUCCESS = 'FETCH_GUESTS_SUCCESS'
export const FETCH_GUESTS_FAIL = 'FETCH_GUESTS_FAIL'
export const FETCH_RELATIVE_GUESTS_START = 'FETCH_RELATIVE_GUESTS_START'
export const FETCH_RELATIVE_GUESTS_SUCCESS = 'FETCH_RELATIVE_GUESTS_SUCCESS'
export const FETCH_RELATIVE_GUESTS_FAIL = 'FETCH_RELATIVE_GUESTS_FAIL'
export const FETCH_INVITACIONES_START = 'FETCH_INVITACIONES_START'
export const FETCH_INVITACIONES_SUCCESS = 'FETCH_INVITACIONES_SUCCESS'
export const FETCH_INVITACIONES_FAIL = 'FETCH_INVITACIONES_FAIL'
export const FETCH_FILTERED_GUESTS_START = 'FETCH_FILTERED_GUESTS_START'
export const FETCH_FILTERED_GUESTS_SUCCESS = 'FETCH_FILTERED_GUESTS_SUCCESS'
export const FETCH_FILTERED_GUESTS_FAIL = 'FETCH_FILTERED_GUESTS_FAIL'

//FAMILIARES
export const FETCH_RELATIVES_START = 'FETCH_RELATIVES_START'
export const FETCH_RELATIVES_SUCCESS = 'FETCH_RELATIVES_SUCCESS'
export const FETCH_RELATIVES_FAIL = 'FETCH_RELATIVES_FAIL'

//AUTOS

export const FETCH_VEHICLES_START = 'FETCH_VEHICLES_START'
export const FETCH_VEHICLES_SUCCESS = 'FETCH_VEHICLES_SUCCESS'
export const FETCH_VEHICLES_FAIL = 'FETCH_VEHICLES_FAIL'

//LOTES

export const FETCH_LOTS_BY_NEIGHBOURHOOD_START = 'FETCH_LOTS_BY_NEIGHBOURHOOD_START'
export const FETCH_LOTS_BY_NEIGHBOURHOOD_SUCCESS = 'FETCH_LOTS_BY_NEIGHBOURHOOD_SUCCESS'
export const FETCH_LOTS_BY_NEIGHBOURHOOD_FAIL = 'FETCH_LOTS_BY_NEIGHBOURHOOD_FAIL'

export const FETCH_EDIT_PERSON_START = 'FETCH_EDIT_PERSON_START'
export const FETCH_EDIT_PERSON_SUCCESS = 'FETCH_EDIT_PERSON_SUCCESS'
export const FETCH_EDIT_PERSON_FAIL = 'FETCH_EDIT_PERSON_FAIL'





//FEED
export const FETCH_EVENTS_START = 'FETCH_EVENTS_START'
export const FETCH_EVENTS_SUCCESS = 'FETCH_EVENTS_SUCCESS'
export const FETCH_EVENTS_FAIL = 'FETCH_EVENTS_FAIL'
export const FETCH_ARTICLES_START = 'FETCH_ARTICLES_START'
export const FETCH_ARTICLES_SUCCESS = 'FETCH_ARTICLES_SUCCESS'
export const FETCH_ARTICLES_FAIL = 'FETCH_ARTICLES_FAIL'
export const CREATE_NEW_EVENT_START = 'CREATE_NEW_EVENT_START'
export const CREATE_NEW_EVENT_SUCCESS = 'CREATE_NEW_EVENT_SUCCESS'
export const CREATE_NEW_EVENT_FAIL = 'CREATE_NEW_EVENT_FAIL'
export const CREATE_NEW_ARTICLE_START = 'CREATE_NEW_ARTICLE_START'
export const CREATE_NEW_ARTICLE_SUCCESS = 'CREATE_NEW_ARTICLE_SUCCESS'
export const CREATE_NEW_ARTICLE_FAIL = 'CREATE_NEW_ARTICLE_FAIL'
export const PRESUBMIT_FILES_START = 'PRESUBMIT_FILES_START'
export const PRESUBMIT_FILES_SUCCESS = 'PRESUBMIT_FILES_SUCCESS'
export const PRESUBMIT_FILES_FAIL = 'PRESUBMIT_FILES_FAIL'
export const EDIT_ARTICLE_START = 'EDIT_ARTICLE_START'
export const EDIT_ARTICLE_SUCCESS = 'EDIT_ARTICLE_SUCCESS'
export const EDIT_ARTICLE_FAIL = 'EDIT_ARTICLE_FAIL'
export const DELETE_ARTICLE_START = 'DELETE_ARTICLE_START'
export const DELETE_ARTICLE_SUCCESS = 'DELETE_ARTICLE_SUCCESS'
export const DELETE_ARTICLE_FAIL = 'DELETE_ARTICLE_FAIL'
export const DELETE_ARTICLE_RESET = 'DELETE_ARTICLE_RESET'
export const DELETE_EVENT_START = 'DELETE_EVENT_START'
export const DELETE_EVENT_SUCCESS = 'DELETE_EVENT_SUCCESS'
export const DELETE_EVENT_FAIL = 'DELETE_EVENT_FAIL'
export const DELETE_EVENT_RESET = 'DELETE_EVENT_RESET'


export const EDIT_EVENT_START = 'EDIT_EVENT_START'
export const EDIT_EVENT_SUCCESS = 'EDIT_EVENT_SUCCESS'
export const EDIT_EVENT_FAIL = 'EDIT_EVENT_FAIL'

export const EDIT_NEWS_START = 'EDIT_NEWS_START'
export const EDIT_NEWS_SUCCESS = 'EDIT_NEWS_SUCCESS'
export const EDIT_NEWS_FAIL = 'EDIT_NEWS_FAIL'


//MENSAJES
export const FETCH_MSJ_RECIBIDO_START = 'FETCH_MSJ_RECIBIDO_START'
export const FETCH_MSJ_RECIBIDO_SUCCESS = 'FETCH_MSJ_RECIBIDO_SUCCESS'
export const FETCH_MSJ_RECIBIDO_FAIL = 'FETCH_MSJ_RECIBIDO_FAIL'
export const CREATE_NEW_PRIVATE_MSJ_START = 'CREATE_NEW_PRIVATE_MSJ_START'
export const CREATE_NEW_PRIVATE_MSJ_SUCCESS = 'CREATE_NEW_PRIVATE_MSJ_SUCCESS'
export const CREATE_NEW_PRIVATE_MSJ_FAIL = 'CREATE_NEW_PRIVATE_MSJ_FAIL'
export const FETCH_MSJ_ENVIADOS_START = 'FETCH_MSJ_ENVIADOS_START'
export const FETCH_MSJ_ENVIADOS_SUCCESS = 'FETCH_MSJ_ENVIADOS_SUCCESS'
export const FETCH_MSJ_ENVIADOS_FAIL = 'FETCH_MSJ_ENVIADOS_FAIL'
export const FETCH_MSJ_UNREAD_START = 'FETCH_MSJ_UNREAD_START'
export const FETCH_MSJ_UNREAD_SUCCESS = 'FETCH_MSJ_UNREAD_SUCCESS'
export const FETCH_MSJ_UNREAD_FAIL = 'FETCH_MSJ_UNREAD_FAIL'
export const CREATE_NEW_PUBLIC_MSJ_START = 'CREATE_NEW_PUBLIC_MSJ_START'
export const CREATE_NEW_PUBLIC_MSJ_SUCCESS = 'CREATE_NEW_PUBLIC_MSJ_SUCCESS'
export const CREATE_NEW_PUBLIC_MSJ_FAIL = 'CREATE_NEW_PUBLIC_MSJ_FAIL'
export const FETCH_USERS_START = 'FETCH_USERS_START'
export const FETCH_USERS_SUCCESS = 'FETCH_USERS_SUCCESS'
export const FETCH_USERS_FAIL = 'FETCH_USERS_FAIL'

//PROVEEDORES
export const FETCH_NEIGHBOURHOOD_START = 'FETCH_NEIGHBOURHOOD_START'
export const FETCH_NEIGHBOURHOOD_SUCCESS = 'FETCH_NEIGHBOURHOOD_SUCCESS'
export const FETCH_NEIGHBOURHOOD_FAIL = 'FETCH_NEIGHBOURHOOD_FAIL'



export const FETCH_CATEGORIAS_START = 'FETCH_CATEGORIAS_START'
export const FETCH_CATEGORIAS_SUCCESS = 'FETCH_CATEGORIAS_SUCCESS'
export const FETCH_CATEGORIAS_FAIL = 'FETCH_CATEGORIAS_FAIL'
export const FETCH_PROVEEDORES_START = 'FETCH_PROVEEDORES_START'
export const FETCH_PROVEEDORES_SUCCESS = 'FETCH_PROVEEDORES_SUCCESS'
export const FETCH_PROVEEDORES_FAIL = 'FETCH_PROVEEDORES_FAIL'
export const FETCH_SERVICES_START = 'FETCH_SERVICES_START'
export const FETCH_SERVICES_SUCCESS = 'FETCH_SERVICES_SUCCESS'
export const FETCH_SERVICES_FAIL = 'FETCH_SERVICES_FAIL'



export const CREATE_SUPPLIERS_START = 'CREATE_SUPPLIERS_START'
export const CREATE_SUPPLIERS_SUCCESS = 'CREATE_SUPPLIERS_SUCCESS'
export const CREATE_SUPPLIERS_FAIL = 'CREATE_SUPPLIERS_FAIL'
export const CREATE_CATEGORY_START = 'CREATE_CATEGORY_START'
export const CREATE_CATEGORY_SUCCESS = 'CREATE_CATEGORY_SUCCESS'
export const CREATE_CATEGORY_FAIL = 'CREATE_CATEGORY_FAIL'
export const CREATE_SERVICE_START = 'CREATE_SERVICE_START'
export const CREATE_SERVICE_SUCCESS = 'CREATE_SERVICE_SUCCESS'
export const CREATE_SERVICE_FAIL = 'CREATE_SERVICE_FAIL'
export const FETCH_SUPPLIERS_REQUESTS_START = 'FETCH_SUPPLIERS_REQUESTS_START'
export const FETCH_SUPPLIERS_REQUESTS_SUCCESS = 'FETCH_SUPPLIERS_REQUESTS_SUCCESS'
export const FETCH_SUPPLIERS_REQUESTS_FAIL = 'FETCH_SUPPLIERS_REQUESTS_FAIL'

//RESERVAS
export const FETCH_ACTIVIDADES_START = 'FETCH_ACTIVIDADES_START'
export const FETCH_ACTIVIDADES_SUCCESS = 'FETCH_ACTIVIDADES_SUCCESS'
export const FETCH_ACTIVIDADES_FAIL = 'FETCH_ACTIVIDADES_FAIL'
export const CREATE_ACTIVIDAD_START = 'CREATE_ACTIVIDAD_START'
export const CREATE_ACTIVIDAD_SUCCESS = 'CREATE_ACTIVIDAD_SUCCESS'
export const CREATE_ACTIVIDAD_FAIL = 'CREATE_ACTIVIDAD_FAIL'
export const FETCH_SPACES_START = 'FETCH_SPACES_START'
export const FETCH_SPACES_SUCCESS = 'FETCH_SPACES_SUCCESS'
export const FETCH_SPACES_FAIL = 'FETCH_SPACES_FAIL'
export const CREATE_SPACE_START = 'CREATE_SPACE_START'
export const CREATE_SPACE_SUCCESS = 'CREATE_SPACE_SUCCESS'
export const CREATE_SPACE_FAIL = 'CREATE_SPACE_FAIL'
export const CREATE_EXCEPTIONS_START = 'CREATE_EXCEPTIONS_START'
export const CREATE_EXCEPTIONS_SUCCESS = 'CREATE_EXCEPTIONS_SUCCESS'
export const CREATE_EXCEPTIONS_FAIL = 'CREATE_EXCEPTIONS_FAIL'
export const FETCH_BOOKING_REQUESTS_START = 'FETCH_BOOKING_REQUESTS_START'
export const FETCH_BOOKING_REQUESTS_SUCCESS = 'FETCH_BOOKING_REQUESTS_SUCCESS'
export const FETCH_BOOKING_REQUESTS_FAIL = 'FETCH_BOOKING_REQUESTS_FAIL'

export const FETCH_DELETE_RESERVA_SUCCESS = 'FETCH_DELETE_RESERVA_SUCCESS'
export const FETCH_DELETE_RESERVA_FAIL = 'FETCH_DELETE_RESERVA_FAIL'
export const FETCH_DELETE_RESERVA_START = 'FETCH_DELETE_RESERVA_START'

export const GENERATE_BOOKING_SUCCESS = 'GENERATE_BOOKING_SUCCESS'
export const GENERATE_BOOKING_FAIL = 'GENERATE_BOOKING_FAIL'
export const GENERATE_BOOKING_START = 'GENERATE_BOOKING_START'

export const FETCH_NO_OWNERS_SUCCESS = 'FETCH_NO_OWNERS_SUCCESS'
export const FETCH_NO_OWNERS_FAIL = 'FETCH_NO_OWNERS_FAIL'
export const FETCH_NO_OWNERS_START = 'FETCH_NO_OWNERS_START'

//NEIGHBOURHOOD
export const FETCH_DEFAULT_ACTIVITIES_START = 'FETCH_DEFAULT_ACTIVITIES_START'
export const FETCH_DEFAULT_ACTIVITIES_SUCCESS = 'FETCH_DEFAULT_ACTIVITIES_SUCCESS'
export const FETCH_DEFAULT_ACTIVITIES_FAIL = 'FETCH_DEFAULT_ACTIVITIES_FAIL'
export const FETCH_DEFAULT_CATEGORIES_START = 'FETCH_DEFAULT_CATEGORIES_START'
export const FETCH_DEFAULT_CATEGORIES_SUCCESS = 'FETCH_DEFAULT_CATEGORIES_SUCCESS'
export const FETCH_DEFAULT_CATEGORIES_FAIL = 'FETCH_DEFAULT_CATEGORIES_FAIL'

//BILLETERA
export const FETCH_TRANSACTION_LIST_FAIL = 'FETCH_TRANSACTION_LIST_FAIL'
export const FETCH_TRANSACTION_LIST_START = 'FETCH_TRANSACTION_LIST_START'
export const FETCH_TRANSACTION_LIST_SUCCESS = 'FETCH_TRANSACTION_LIST_SUCCESS'
export const HAS_MORE_TRANSACTION_LIST_SUCCESS = 'HAS_MORE_TRANSACTION_LIST_SUCCESS'
export const FETCH_TRANSACTION_QR_FAIL = 'FETCH_TRANSACTION_QR_FAIL'
export const FETCH_TRANSACTION_QR_START = 'FETCH_TRANSACTION_QR_START'
export const FETCH_TRANSACTION_QR_SUCCESS = 'FETCH_TRANSACTION_QR_SUCCESS'
export const FETCH_REQUESTED_TRANSACTION_SUCCESS = 'FETCH_REQUESTED_TRANSACTION_SUCCESS'
export const FETCH_REQUESTED_TRANSACTION_FAIL = 'FETCH_REQUESTED_TRANSACTION_FAIL'
export const FETCH_REQUESTED_TRANSACTION_START = 'FETCH_REQUESTED_TRANSACTION_START'
export const FETCH_GET_CODE_QR_IMAGE_START = 'FETCH_GET_CODE_QR_IMAGE_START'
export const FETCH_GET_CODE_QR_IMAGE_FAIL = 'FETCH_GET_CODE_QR_IMAGE_FAIL'
export const FETCH_GET_CODE_QR_IMAGE_SUCCESS = 'FETCH_GET_CODE_QR_IMAGE_SUCCESS'
export const FETCH_GET_ACCOUNT_SUCCESS = 'FETCH_GET_ACCOUNT_SUCCESS'
export const FETCH_GET_ACCOUNT_FAIL = 'FETCH_GET_ACCOUNT_FAIL'
export const FETCH_GET_ACCOUNT_START = 'FETCH_GET_ACCOUNT_START'
export const FETCH_GET_ACCOUNT_BY_LOTE_SUCCESS = 'FETCH_GET_ACCOUNT_BY_LOTE_SUCCESS'
export const FETCH_GET_ACCOUNT_BY_LOTE_FAIL = 'FETCH_GET_ACCOUNT_BY_LOTE_FAIL'
export const FETCH_GET_ACCOUNT_BY_LOTE_START = 'FETCH_GET_ACCOUNT_BY_LOTE_START'
export const FETCH_GET_ACCOUNT_DEBIT_SUCCESS = 'FETCH_GET_ACCOUNT_DEBIT_SUCCESS'
export const FETCH_GET_ACCOUNT_DEBIT_FAIL = 'FETCH_GET_ACCOUNT_DEBIT_FAIL'
export const FETCH_GET_ACCOUNT_DEBIT_START = 'FETCH_GET_ACCOUNT_DEBIT_START'
export const FETCH_CREATE_TRANSACTION_REQUEST_SUCCESS = 'FETCH_CREATE_TRANSACTION_REQUEST_SUCCESS'
export const FETCH_CREATE_TRANSACTION_REQUEST_FAIL = 'FETCH_CREATE_TRANSACTION_REQUEST_FAIL'
export const FETCH_CREATE_TRANSACTION_REQUEST_START = 'FETCH_CREATE_TRANSACTION_REQUEST_START'
export const FETCH_GET_NUMBER_OF_TRANSFER_PENDING_SUCCESS = 'FETCH_GET_NUMBER_OF_TRANSFER_PENDING_SUCCESS'
export const FETCH_GET_NUMBER_OF_TRANSFER_PENDING_FAIL = 'FETCH_GET_NUMBER_OF_TRANSFER_PENDING_FAIL'
export const FETCH_GET_NUMBER_OF_TRANSFER_PENDING_START = 'FETCH_GET_NUMBER_OF_TRANSFER_PENDING_START'
export const FETCH_GET_TRANSFER_PENDING_SUCCESS = 'FETCH_GET_TRANSFER_PENDING_SUCCESS'
export const FETCH_GET_TRANSFER_PENDING_FAIL = 'FETCH_GET_TRANSFER_PENDING_FAIL'
export const FETCH_GET_TRANSFER_PENDING_START = 'FETCH_GET_TRANSFER_PENDING_START'
export const FETCH_APPROVE_TRANSACTION_SUCCESS = 'FETCH_APPROVE_TRANSACTION_SUCCESS'
export const FETCH_APPROVE_TRANSACTION_FAIL = 'FETCH_APPROVE_TRANSACTION_FAIL'
export const FETCH_APPROVE_TRANSACTION_START = 'FETCH_APPROVE_TRANSACTION_START'
export const FETCH_DENIED_TRANSACTION_SUCCESS = 'FETCH_DENIED_TRANSACTION_SUCCESS'
export const FETCH_DENIED_TRANSACTION_FAIL = 'FETCH_DENIED_TRANSACTION_FAIL'
export const FETCH_DENIED_TRANSACTION_START = 'FETCH_DENIED_TRANSACTION_START'
export const RESET_TRANSACTION_OPERATION_RESPONSE = 'RESET_TRANSACTION_OPERATION_RESPONSE'
export const FETCH_RESEND_TOKEN_SUCCESS = 'FETCH_RESEND_TOKEN_SUCCESS'
export const FETCH_RESEND_TOKEN_FAIL = 'FETCH_RESEND_TOKEN_FAIL'
export const FETCH_RESEND_TOKEN_START = 'FETCH_RESEND_TOKEN_START'
export const FETCH_GET_ACCOUNT_QR_SUCCESS = 'FETCH_GET_ACCOUNT_QR_SUCCESS'
export const FETCH_GET_ACCOUNT_QR_FAIL = 'FETCH_GET_ACCOUNT_QR_FAIL'
export const FETCH_GET_ACCOUNT_QR_START = 'FETCH_GET_ACCOUNT_QR_START'
export const FETCH_BALANCE_SUCCESS = 'FETCH_BALANCE_SUCCESS'
export const FETCH_BALANCE_FAIL = 'FETCH_BALANCE_FAIL'
export const FETCH_BALANCE_START = 'FETCH_BALANCE_START'
export const FETCH_TRANSACTION_CHARGE_CARD_SUCCESS = 'FETCH_TRANSACTION_CHARGE_CARD_SUCCESS'
export const FETCH_TRANSACTION_CHARGE_CARD_FAIL = 'FETCH_TRANSACTION_CHARGE_CARD_FAIL'
export const FETCH_TRANSACTION_CHARGE_CARD_START = 'FETCH_TRANSACTION_CHARGE_CARD_START'
export const FETCH_DEBIT_ACCOUNTS_SUCCESS = 'FETCH_DEBIT_ACCOUNTS_SUCCESS'
export const FETCH_DEBIT_ACCOUNTS_FAIL = 'FETCH_DEBIT_ACCOUNTS_FAIL'
export const FETCH_DEBIT_ACCOUNTS_START = 'FETCH_DEBIT_ACCOUNTS_START'
export const SET_BANK_ACCOUNT = 'SET_BANK_ACCOUNT'
export const SET_PAGE = 'SET_PAGE'
export const SAVE_TRANSACTION_AUTH = 'SAVE_TRANSACTION_AUTH'
export const FETCH_TRANSACTION_DATA_SUCCESS = 'FETCH_TRANSACTION_DATA_SUCCESS'
export const FETCH_TRANSACTION_DATA_FAIL = 'FETCH_TRANSACTION_DATA_FAIL'
export const FETCH_TRANSACTION_DATA_START = 'FETCH_TRANSACTION_DATA_START'
export const FETCH_GET_TOKEN_PAYMENT_SUCCESS = 'FETCH_GET_TOKEN_PAYMENT_SUCCESS'
export const FETCH_GET_TOKEN_PAYMENT_FAIL = 'FETCH_GET_TOKEN_PAYMENT_FAIL'
export const FETCH_GET_TOKEN_PAYMENT_START = 'FETCH_GET_TOKEN_PAYMENT_START'

export const FETCH_DOWNLOAD_SUMMARY_SUCCESS = 'FETCH_DOWNLOAD_SUMMARY_SUCCESS'
export const FETCH_DOWNLOAD_SUMMARY_FAIL = 'FETCH_DOWNLOAD_SUMMARY_FAIL'
export const FETCH_DOWNLOAD_SUMMARY_START = 'FETCH_DOWNLOAD_SUMMARY_START'
export const RESET_DOWNLOAD_SUMMARY = 'RESET_DOWNLOAD_SUMMARY'

export const FETCH_PAYMENT_PAYMI_SUCCESS = 'FETCH_PAYMENT_PAYMI_SUCCESS'
export const FETCH_PAYMENT_PAYMI_FAIL = 'FETCH_PAYMENT_PAYMI_FAIL'
export const FETCH_PAYMENT_PAYMI_START = 'FETCH_PAYMENT_PAYMI_START'

//EXPENSES
export const FETCH_GENERATE_EXPENSES_SUCCESS = 'FETCH_GENERATE_EXPENSES_SUCCESS'
export const FETCH_GENERATE_EXPENSES_START = 'FETCH_GENERATE_EXPENSES_START'
export const FETCH_GENERATE_EXPENSES_FAIL = 'FETCH_GENERATE_EXPENSES_FAIL'
export const SET_DETAIL_PAGE = 'SET_DETAIL_PAGE'

export const FETCH_AUTOMATIC_GENERATE_EXPENSES_SUCCESS = 'FETCH_AUTOMATIC_GENERATE_EXPENSES_SUCCESS'
export const FETCH_AUTOMATIC_GENERATE_EXPENSES_START = 'FETCH_AUTOMATIC_GENERATE_EXPENSES_START'
export const FETCH_AUTOMATIC_GENERATE_EXPENSES_FAIL = 'FETCH_AUTOMATIC_GENERATE_EXPENSES_FAIL'
export const FETCH_AUTOMATIC_GENERATE_EXPENSES_RESET = 'FETCH_AUTOMATIC_GENERATE_EXPENSES_RESET'

export const FETCH_GET_HOME_EXPENSES_SUCCESS = 'FETCH_GET_HOME_EXPENSES_SUCCESS'
export const FETCH_GET_HOME_EXPENSES_START = 'FETCH_GET_HOME_EXPENSES_START'
export const FETCH_GET_HOME_EXPENSES_FAIL = 'FETCH_GET_HOME_EXPENSES_FAIL'

export const FETCH_GET_EXPENSES_CLIENTE_SUCCESS = 'FETCH_GET_EXPENSES_CLIENTE_SUCCESS'
export const FETCH_GET_EXPENSES_CLIENTE_START = 'FETCH_GET_EXPENSES_CLIENTE_START'
export const FETCH_GET_EXPENSES_CLIENTE_FAIL = 'FETCH_GET_EXPENSES_CLIENTE_FAIL'

export const FETCH_GET_FILE_DETAIL_CLIENT_SUCCESS = 'FETCH_GET_FILE_DETAIL_CLIENT_SUCCESS'
export const FETCH_GET_FILE_DETAIL_CLIENT_START = 'FETCH_GET_FILE_DETAIL_CLIENT_START'
export const FETCH_GET_FILE_DETAIL_CLIENT_FAIL = 'FETCH_GET_FILE_DETAIL_CLIENT_FAIL'

export const FETCH_GET_FILE_DETAIL_NEIGHBOURHOOD_SUCCESS = 'FETCH_GET_FILE_DETAIL_NEIGHBOURHOOD_SUCCESS'
export const FETCH_GET_FILE_DETAIL_NEIGHBOURHOOD_START = 'FETCH_GET_FILE_DETAIL_NEIGHBOURHOOD_START'
export const FETCH_GET_FILE_DETAIL_NEIGHBOURHOOD_FAIL = 'FETCH_GET_FILE_DETAIL_NEIGHBOURHOOD_FAIL'

export const FETCH_POST_UPLOAD_FILE_SUCCESS = 'FETCH_POST_UPLOAD_FILE_SUCCESS'
export const FETCH_POST_UPLOAD_FILE_START = 'FETCH_POST_UPLOAD_FILE_START'
export const FETCH_POST_UPLOAD_FILE_FAIL = 'FETCH_POST_UPLOAD_FILE_FAIL'

export const FETCH_EXPENSES_REPORT_SUCCESS = 'FETCH_EXPENSES_REPORT_SUCCESS'
export const FETCH_EXPENSES_REPORT_FAIL = 'FETCH_EXPENSES_REPORT_FAIL'
export const FETCH_EXPENSES_REPORT_START = 'FETCH_EXPENSES_REPORT_START'

export const FETCH_ORDER_LIST_SUCCESS = 'FETCH_ORDER_LIST_SUCCESS'
export const FETCH_ORDER_LIST_FAIL = 'FETCH_ORDER_LIST_FAIL'
export const FETCH_ORDER_LIST_START = 'FETCH_ORDER_LIST_START'

export const SET_ORDER_PAGE = 'SET_ORDER_PAGE'
export const SET_ORDER_YEAR = 'SET_ORDER_YEAR'


//PROFILE
export const FETCH_GET_ROLES_SUCCESS = 'FETCH_GET_ROLES_SUCCESS'
export const FETCH_GET_ROLES_FAIL = 'FETCH_GET_ROLES_FAIL'
export const FETCH_GET_ROLES_START = 'FETCH_GET_ROLES_START'

export const FETCH_GET_MENUS_SUCCESS = 'FETCH_GET_MENUS_SUCCESS'
export const FETCH_GET_MENUS_FAIL = 'FETCH_GET_MENUS_FAIL'
export const FETCH_GET_MENUS_START = 'FETCH_GET_MENUS_START'

export const FETCH_GET_PERSON_SUCCESS = 'FETCH_GET_PERSON_SUCCESS'
export const FETCH_GET_PERSON_FAIL = 'FETCH_GET_PERSON_FAIL'
export const FETCH_GET_PERSON_START = 'FETCH_GET_PERSON_START'

export const FETCH_REFRESH_MENUS_FOR_ROLE_SUCCESS = 'FETCH_REFRESH_MENUS_FOR_ROLE_SUCCESS'
export const FETCH_REFRESH_MENUS_FOR_ROLE_FAIL = 'FETCH_REFRESH_MENUS_FOR_ROLE_FAIL'
export const FETCH_REFRESH_MENUS_FOR_ROLE_START = 'FETCH_REFRESH_MENUS_FOR_ROLE_START'


//ACCESOS

export const FETCH_GET_GUEST_SUCCESS = 'FETCH_GET_GUEST_SUCCESS'
export const FETCH_GET_GUEST_FAIL = 'FETCH_GET_GUEST_FAIL'
export const FETCH_GET_GUEST_START = 'FETCH_GET_GUEST_START'
export const CLEAR_ACCESS_STORE = 'CLEAR_ACCESS_STORE'
export const FETCH_CHECK_IN_SUCCESS = 'FETCH_CHECK_IN_SUCCESS'
export const FETCH_CHECK_IN_FAIL = 'FETCH_CHECK_IN_FAIL'
export const FETCH_CHECK_IN_START = 'FETCH_CHECK_IN_START'
export const FETCH_VEHICLES_BRAND_START = 'FETCH_VEHICLES_BRAND_START'
export const FETCH_VEHICLES_BRAND_SUCCESS = 'FETCH_VEHICLES_BRAND_SUCCESS'
export const FETCH_VEHICLES_BRAND_FAIL = 'FETCH_VEHICLES_BRAND_FAIL'
export const FETCH_VEHICLES_MODEL_START = 'FETCH_VEHICLES_MODEL_START'
export const FETCH_VEHICLES_MODEL_SUCCESS = 'FETCH_VEHICLES_MODEL_SUCCESS'
export const FETCH_VEHICLES_MODEL_FAIL = 'FETCH_VEHICLES_MODEL_FAIL'
export const FETCH_NOTIFICATIONS_START = 'FETCH_NOTIFICATIONS_START'
export const FETCH_NOTIFICATIONS_SUCCESS = 'FETCH_NOTIFICATIONS_SUCCESS'
export const FETCH_NOTIFICATIONS_FAIL = 'FETCH_NOTIFICATIONS_FAIL'
export const FETCH_MENSAJERIA_START = 'FETCH_MENSAJERIA_START'
export const FETCH_MENSAJERIA_SUCCESS = 'FETCH_MENSAJERIA_SUCCESS'
export const FETCH_MENSAJERIA_FAIL = 'FETCH_MENSAJERIA_FAIL'
export const FETCH_REGISTROS_START = 'FETCH_REGISTROS_START'
export const FETCH_REGISTROS_SUCCESS = 'FETCH_REGISTROS_SUCCESS'
export const FETCH_REGISTROS_FAIL = 'FETCH_REGISTROS_FAIL'



//TICKETS

export const FETCH_TICKETS_SUCCESS = 'FETCH_TICKETS_SUCCESS'
export const FETCH_TICKETS_FAIL = 'FETCH_TICKETS_FAIL'
export const FETCH_TICKETS_START = 'FETCH_TICKETS_START'

export const RESET_MODAL_STATE_REDUX = 'RESET_MODAL_STATE_REDUX'

export const FETCH_CATEGORIES_BY_ROLE_SUCCESS = 'FETCH_CATEGORIES_BY_ROLE_SUCCESS'
export const FETCH_CATEGORIES_BY_ROLE_FAIL = 'FETCH_CATEGORIES_BY_ROLE_FAIL'
export const FETCH_CATEGORIES_BY_ROLE_START = 'FETCH_CATEGORIES_BY_ROLE_START'

export const FETCH_TICKET_MESSAGES_SUCCESS = 'FETCH_TICKET_MESSAGES_SUCCESS'
export const FETCH_TICKET_MESSAGES_FAIL = 'FETCH_TICKET_MESSAGES_FAIL'
export const FETCH_TICKET_MESSAGES_START = 'FETCH_TICKET_MESSAGES_START'

export const SEND_TICKET_MESSAGE_SUCCESS = 'SEND_TICKET_MESSAGE_SUCCESS'
export const SEND_TICKET_MESSAGE_FAIL = 'SEND_TICKET_MESSAGE_FAIL'
export const SEND_TICKET_MESSAGE_START = 'SEND_TICKET_MESSAGE_START'

export const CLOSE_TICKET_SUCCESS = 'CLOSE_TICKET_SUCCESS'
export const CLOSE_TICKET_FAIL = 'CLOSE_TICKET_FAIL'
export const CLOSE_TICKET_START = 'CLOSE_TICKET_START'

export const PENDING_TICKET_SUCCESS = 'PENDING_TICKET_SUCCESS'
export const PENDING_TICKET_FAIL = 'PENDING_TICKET_FAIL'
export const PENDING_TICKET_START = 'PENDING_TICKET_START'

export const CHANGE_CATEGORY_TICKET_SUCCESS = 'CHANGE_CATEGORY_TICKET_SUCCESS'
export const CHANGE_CATEGORY_TICKET_FAIL = 'CHANGE_CATEGORY_TICKET_FAIL'
export const CHANGE_CATEGORY_TICKET_START = 'CHANGE_CATEGORY_TICKET_START'

export const SEARCH_TICKETS_SUCCESS = 'SEARCH_TICKETS_SUCCESS'
export const SEARCH_TICKETS_FAIL = 'SEARCH_TICKETS_FAIL'
export const SEARCH_TICKETS_START = 'SEARCH_TICKETS_START'

export const FETCH_PUT_INVITE_SUCCESS = 'FETCH_PUT_INVITE_SUCCESS'
export const FETCH_PUT_INVITE_FAIL = 'FETCH_PUT_INVITE_FAIL'
export const FETCH_PUT_INVITE_START = 'FETCH_PUT_INVITE_START'

export const FETCH_POST_INVITE_SUCCESS = 'FETCH_POST_INVITE_SUCCESS'
export const FETCH_POST_INVITE_FAIL = 'FETCH_POST_INVITE_FAIL'
export const FETCH_POST_INVITE_START = 'FETCH_POST_INVITE_START'

export const FETCH_CHECK_OUT_SUCCESS = 'FETCH_CHECK_OUT_SUCCESS'
export const FETCH_CHECK_OUT_FAIL = 'FETCH_CHECK_OUT_FAIL'
export const FETCH_CHECK_OUT_START = 'FETCH_CHECK_OUT_START'

export const FETCH_SEARCH_INVITES_SUCCESS = 'FETCH_SEARCH_INVITES_SUCCESS'
export const FETCH_SEARCH_INVITES_FAIL = 'FETCH_SEARCH_INVITES_FAIL'
export const FETCH_SEARCH_INVITES_START = 'FETCH_SEARCH_INVITES_START'
export const CLEAR_SEARCHED_INVITES = 'CLEAR_SEARCHED_INVITES'

export const FETCH_TICKET_CATEGORIES_SUCCESS = 'FETCH_TICKET_CATEGORIES_SUCCESS'
export const FETCH_TICKET_CATEGORIES_FAIL = 'FETCH_TICKET_CATEGORIES_FAIL'
export const FETCH_TICKET_CATEGORIES_START = 'FETCH_TICKET_CATEGORIES_START'

export const FETCH_TICKET_TYPES_BY_CATEGORY_SUCCESS = 'FETCH_TICKET_TYPES_BY_CATEGORY_SUCCESS'
export const FETCH_TICKET_TYPES_BY_CATEGORY_FAIL = 'FETCH_TICKET_TYPES_BY_CATEGORY_FAIL'
export const FETCH_TICKET_TYPES_BY_CATEGORY_START = 'FETCH_TICKET_TYPES_BY_CATEGORY_START'

// usuarios
export const FETCH_GETUSERS_SUCCESS = 'FETCH_GETUSERS_SUCCESS'
export const FETCH_GETUSERS_FAIL = 'FETCH_GETUSERS_FAIL'
export const FETCH_GETUSERS_START = 'FETCH_GETUSERS_START'

//crear
export const FETCH_NEW_USER_SUCCESS = 'FETCH_NEW_USER_SUCCESS'
export const FETCH_NEW_USER_FAIL = 'FETCH_NEW_USER_FAIL'
export const FETCH_NEW_USER_START = 'FETCH_NEW_USER_START'

//editar
export const FETCH_EDIT_USER_SUCCESS = 'FETCH_EDIT_USER_SUCCESS'
export const FETCH_EDIT_USER_FAIL = 'FETCH_EDIT_USER_FAIL'
export const FETCH_EDIT_USER_START = 'FETCH_EDIT_USER_START'
export const FETCH_EDIT_USER_RESET = 'FETCH_EDIT_USER_RESET'

//eliminar
export const DELETE_USUARIO_SUCCESS = 'DELETE_USUARIO_SUCCESS'
export const DELETE_USUARIO_FAIL = 'DELETE_USUARIO_FAIL'
export const DELETE_USUARIO_START = 'DELETE_USUARIO_START'

//accesos
export const FETCH_LOTS_SUCCESS = 'FETCH_LOTS_SUCCESS'
export const FETCH_LOTS_FAIL = 'FETCH_LOTS_FAIL'
export const FETCH_LOTS_START = 'FETCH_LOTS_START'

//Clasificados
export const FETCH_GET_PRODUCTS_SUCCESS = 'FETCH_GET_PRODUCTS_SUCCESS'
export const FETCH_GET_PRODUCTS_FAIL = 'FETCH_GET_PRODUCTS_FAIL'
export const FETCH_GET_PRODUCTS_START = 'FETCH_GET_PRODUCTS_START'

export const FETCH_DETAIL_PRODUCT_SUCCESS = 'FETCH_DETAIL_PRODUCT_SUCCESS'
export const FETCH_DETAIL_PRODUCT_FAIL = 'FETCH_DETAIL_PRODUCT_FAIL'
export const FETCH_DETAIL_PRODUCT_START = 'FETCH_DETAIL_PRODUCT_START'

export const FETCH_DELETE_PRODUCT_SUCCESS = 'FETCH_DELETE_PRODUCT_SUCCESS'
export const FETCH_DELETE_PRODUCT_FAIL = 'FETCH_DELETE_PRODUCT_FAIL'
export const FETCH_DELETE_PRODUCT_START = 'FETCH_DELETE_PRODUCT_START'
export const FETCH_DELETE_PRODUCT_RESET = 'FETCH_DELETE_PRODUCT_RESET'

export const FETCH_ADD_PRODUCT_SUCCESS = 'FETCH_ADD_PRODUCT_SUCCESS'
export const FETCH_ADD_PRODUCT_FAIL = 'FETCH_ADD_PRODUCT_FAIL'
export const FETCH_ADD_PRODUCT_START = 'FETCH_ADD_PRODUCT_START'
export const FETCH_ADD_PRODUCT_RESET = 'FETCH_ADD_PRODUCT_RESET'

export const FETCH_EDIT_PRODUCT_SUCCESS = 'FETCH_EDIT_PRODUCT_SUCCESS'
export const FETCH_EDIT_PRODUCT_FAIL = 'FETCH_EDIT_PRODUCT_FAIL'
export const FETCH_EDIT_PRODUCT_START = 'FETCH_EDIT_PRODUCT_START'
export const FETCH_EDIT_PRODUCT_RESET = 'FETCH_EDIT_PRODUCT_RESET'



export const FETCH_GET_CATEGORIES_SUCCESS = 'FETCH_GET_CATEGORIES_SUCCESS'
export const FETCH_GET_CATEGORIES_START = 'FETCH_GET_CATEGORIES_START'
export const FETCH_GET_CATEGORIES_FAIL = 'FETCH_GET_CATEGORIES_FAIL'
export const FETCH_GET_CATEGORIES_RESET = 'FETCH_GET_CATEGORIES_RESET'

export const FETCH_GET_SHIPPING_SUCCESS = 'FETCH_GET_SHIPPING_SUCCESS'
export const FETCH_GET_SHIPPING_START = 'FETCH_GET_SHIPPING_START'
export const FETCH_GET_SHIPPING_FAIL = 'FETCH_GET_SHIPPING_FAIL'
export const FETCH_GET_SHIPPING_RESET = 'FETCH_GET_SHIPPING_RESET'

export const FETCH_GET_CONDITIONS_SUCCESS = 'FETCH_GET_CONDITIONS_SUCCESS'
export const FETCH_GET_CONDITIONS_START = 'FETCH_GET_CONDITIONS_START'
export const FETCH_GET_CONDITIONS_FAIL = 'FETCH_GET_CONDITIONS_FAIL'
export const FETCH_GET_CONDITIONS_RESET = 'FETCH_GET_CONDITIONS_RESET'

export const FETCH_GET_PAYMENT_SUCCESS = 'FETCH_GET_PAYMENT_SUCCESS'
export const FETCH_GET_PAYMENT_START = 'FETCH_GET_PAYMENT_START'
export const FETCH_GET_PAYMENT_FAIL = 'FETCH_GET_PAYMENT_FAIL'
export const FETCH_GET_PAYMENT_RESET = 'FETCH_GET_PAYMENT_RESET'

export const FETCH_DESPACHOS_SUCCESS = 'FETCH_DESPACHOS_SUCCESS'
export const FETCH_DESPACHOS_START = 'FETCH_DESPACHOS_START'
export const FETCH_DESPACHOS_FAIL = 'FETCH_DESPACHOS_FAIL'
export const FETCH_DESPACHOS_RESET = 'FETCH_DESPACHOS_RESET'

export const FETCH_MSG_SUCCESS = 'FETCH_MSG_SUCCESS'
export const FETCH_MSG_START = 'FETCH_MSG_START'
export const FETCH_MSG_FAIL = 'FETCH_MSG_FAIL'
export const FETCH_MSG_RESET = 'FETCH_MSG_RESET'

export const SEND_MSG_START = 'SEND_MSG_START'
export const SEND_MSG_FAIL = 'SEND_MSG_FAIL'
export const SEND_MSG_SUCCESS = 'SEND_MSG_SUCCESS'
export const SEND_MSG_RESET = 'SEND_MSG_RESET'

export const GET_MSG_SUCCESS = 'GET_MSG_SUCCESS'
export const GET_MSG_START = 'GET_MSG_START'
export const GET_MSG_FAIL = 'GET_MSG_FAIL'
export const GET_MSG_RESET = 'GET_MSG_RESET'

export const FETCH_UPLOAD_PRODUCTS_FILE_SUCCESS = 'FETCH_UPLOAD_PRODUCTS_FILE_SUCCESS'
export const FETCH_UPLOAD_PRODUCTS_FILE_START = 'FETCH_UPLOAD_PRODUCTS_FILE_START'
export const FETCH_UPLOAD_PRODUCTS_FILE_FAIL = 'FETCH_UPLOAD_PRODUCTS_FILE_FAIL'
export const FETCH_UPLOAD_PRODUCTS_FILE_RESET = 'FETCH_UPLOAD_PRODUCTS_FILE_RESET'

export const NEW_CONVER_SUCCESS = 'NEW_CONVER_SUCCESS'
export const NEW_CONVER_START = 'NEW_CONVER_START'
export const NEW_CONVER_FAIL = 'NEW_CONVER_FAIL'
export const NEW_CONVER_RESET = 'NEW_CONVER_RESET'

export const FETCH_VENTAS_SUCCESS = 'FETCH_VENTAS_SUCCESS'
export const FETCH_VENTAS_START = 'FETCH_VENTAS_START'
export const FETCH_VENTAS_FAIL = 'FETCH_VENTAS_FAIL'
export const FETCH_VENTAS_RESET = 'FETCH_VENTAS_RESET'

export const FETCH_READY_TO_DELIVER_SUCCESS = 'FETCH_READY_TO_DELIVER_SUCCESS'
export const FETCH_READY_TO_DELIVER_START = 'FETCH_READY_TO_DELIVER_START'
export const FETCH_READY_TO_DELIVER_FAIL = 'FETCH_READY_TO_DELIVER_FAIL'
export const FETCH_READY_TO_DELIVER_RESET = 'FETCH_READY_TO_DELIVER_RESET'

export const FETCH_GET_QR_ORDER_SUCCESS = 'FETCH_GET_QR_ORDER_SUCCESS'
export const FETCH_GET_QR_ORDER_START = 'FETCH_GET_QR_ORDER_START'
export const FETCH_GET_QR_ORDER_FAIL = 'FETCH_GET_QR_ORDER_FAIL'
export const FETCH_GET_QR_ORDER_RESET = 'FETCH_GET_QR_ORDER_RESET'


export const DISCLAIMER_METODO_PAGO_SUCCESS = 'DISCLAIMER_METODO__PAGO_SUCCESS'
export const DISCLAIMER_METODO_PAGO_FAIL = 'DISCLAIMER_METODO_PAGO_FAIL'
export const DISCLAIMER_METODO_PAGO_START = 'DISCLAIMER_METODO_PAGO_START'
export const DISCLAIMER_METODO_PAGO_RESET = 'DISCLAIMER_METODO_PAGO_RESET'

// Reportes - Mascotas
export const FETCH_REPORTE_MASCOTAS_SUCCESS = 'FETCH_REPORTES_MASCOTAS_SUCCESS'
export const FETCH_REPORTE_MASCOTAS_FAIL = 'FETCH_REPORTES_MASCOTAS_FAIL'
export const FETCH_REPORTE_MASCOTAS_START = 'FETCH_REPORTES_MASCOTAS_START'

// Reportes - Tickets
export const FETCH_REPORTE_TICKETS_SUCCESS = 'FETCH_REPORTES_TICKETS_SUCCESS'
export const FETCH_REPORTE_TICKETS_FAIL = 'FETCH_REPORTES_TICKETS_FAIL'
export const FETCH_REPORTE_TICKETS_START = 'FETCH_REPORTES_TICKETS_START'

// Reportes - Reservas
export const FETCH_BOOKINGREPORT_REQUEST_FAIL = 'FETCH_BOOKINGREPORT_REQUEST_FAIL'
export const FETCH_BOOKINGREPORT_REQUEST_SUCCESS = 'FETCH_BOOKINGREPORT_REQUEST_SUCCESS'
export const FETCH_BOOKINGREPORT_REQUEST_START = 'FETCH_BOOKINGREPORT_REQUEST_START'

export const FETCH_USERPROPERTY_REQUEST_FAIL = 'FETCH_USERPROPERTY_REQUEST_FAIL'
export const FETCH_USERPROPERTY_REQUEST_SUCCESS = 'FETCH_USERPROPERTY_REQUEST_SUCCESS'
export const FETCH_USERPROPERTY_REQUEST_START = 'FETCH_USERPROPERTY_REQUEST_START'
// Reportes - EXPENSES
export const FETCH_REPORTE_EXPENSES_SUCCESS = 'FETCH_REPORTE_EXPENSES_SUCCESS'
export const FETCH_REPORTE_EXPENSES_START = 'FETCH_REPORTE_EXPENSES_START'
export const FETCH_REPORTE_EXPENSES_FAIL = 'FETCH_REPORTE_EXPENSES_FAIL'

// Reportes - CLIENTS BY NEIGHBOURHOOD 
export const FETCH_REPORTE_CLIENTES_NEIGHBOURHOOD_START = 'FETCH_REPORTE_CLIENTES_NEIGHBOURHOOD_START'
export const FETCH_REPORTE_CLIENTES_NEIGHBOURHOOD_SUCCESS = 'FETCH_REPORTE_CLIENTES_NEIGHBOURHOOD_SUCCESS'
export const FETCH_REPORTE_CLIENTES_NEIGHBOURHOOD_FAIL = 'FETCH_REPORTE_CLIENTES_NEIGHBOURHOOD_FAIL'

export const FETCH_REPORTE_BILLETERAS_POR_BARRIO_SUCCESS = 'FETCH_REPORTE_BILLETERAS_POR_BARRIO_SUCCESS'
export const FETCH_REPORTE_BILLETERAS_POR_BARRIO_FAIL = 'FETCH_REPORTE_BILLETERAS_POR_BARRIO_FAIL'
export const FETCH_REPORTE_BILLETERAS_POR_BARRIO_START = 'FETCH_REPORTE_BILLETERAS_POR_BARRIO_START'

// Dog Report - Users Funnel
export const FETCH_USERS_FUNNEL_DATA_SUCCESS = 'FETCH_USERS_FUNNEL_DATA_SUCCESS'
export const FETCH_USERS_FUNNEL_DATA_FAIL = 'FETCH_USERS_FUNNEL_DATA_FAIL'
export const FETCH_USERS_FUNNEL_DATA_START = 'FETCH_USERS_FUNNEL_DATA_START'

// Dog Report - Properties Funnel
export const FETCH_PROPERTIES_FUNNEL_DATA_SUCCESS = 'FETCH_PROPERTIES_FUNNEL_DATA_SUCCESS'
export const FETCH_PROPERTIES_FUNNEL_DATA_FAIL = 'FETCH_PROPERTIES_FUNNEL_DATA_FAIL'
export const FETCH_PROPERTIES_FUNNEL_DATA_START = 'FETCH_PROPERTIES_FUNNEL_DATA_START'

// DASHBOARD
export const FETCH_BILLETERA_DASH_START = 'FETCH_BILLETERA_DASH_START'
export const FETCH_BILLETERA_DASH_SUCCESS = 'FETCH_BILLETERA_DASH_SUCCESS'
export const FETCH_BILLETERA_DASH_FAIL = 'FETCH_BILLETERA_DASH_FAIL'
export const FETCH_PERSONA_DASH_START = 'FETCH_PERSONA_DASH_START'
export const FETCH_PERSONA_DASH_SUCCESS = 'FETCH_PERSONA_DASH_SUCCESS'
export const FETCH_PERSONA_DASH_FAIL = 'FETCH_PERSONA_DASH_FAIL'

// Payment Gateway
// Payment Operations
export const FETCH_PAYMENT_OPERATIONS_SUCCESS = 'FETCH_PAYMENT_OPERATIONS_SUCCESS'
export const FETCH_PAYMENT_OPERATIONS_FAIL = 'FETCH_PAYMENT_OPERATIONS_FAIL'
export const FETCH_PAYMENT_OPERATIONS_START = 'FETCH_PAYMENT_OPERATIONS_START'
export const PAYMENT_OPERATIONS_UPDATEGRID = 'PAYMENT_OPERATIONS_UPDATEGRID'
export const RESET_PAYMENT_OPERATIONS_PAGE = 'RESET_PAYMENT_OPERATIONS_PAGE'

export const FETCH_PAYMENT_OPERATION_DETAIL_SUCCESS = 'FETCH_PAYMENT_OPERATION_DETAIL_SUCCESS'
export const FETCH_PAYMENT_OPERATION_DETAIL_FAIL = 'FETCH_PAYMENT_OPERATION_DETAIL_FAIL'
export const FETCH_PAYMENT_OPERATION_DETAIL_START = 'FETCH_PAYMENT_OPERATION_DETAIL_START'
export const PAYMENT_OPERATION_DETAIL_UPDATE = 'PAYMENT_OPERATION_DETAIL_UPDATE'

export const CANCEL_PAYMENT_OPERATION_SUCCESS = 'CANCEL_PAYMENT_OPERATION_SUCCESS'
export const CANCEL_PAYMENT_OPERATION_START = 'CANCEL_PAYMENT_OPERATION_START'
export const CANCEL_PAYMENT_OPERATION_FAIL = 'CANCEL_PAYMENT_OPERATION_FAIL'
export const CANCEL_PAYMENT_OPERATION_RESET = 'CANCEL_PAYMENT_OPERATION_RESET'


export const FETCH_ERROR_DETAIL_SUCCESS = 'FETCH_ERROR_DETAIL_SUCCESS'
export const FETCH_ERROR_DETAIL_START = 'FETCH_ERROR_DETAIL_START'
export const FETCH_ERROR_DETAIL_FAIL = 'FETCH_ERROR_DETAIL_FAIL'

export const FETCH_PAYMENT_OPERATIONS_REPORT_SUCCESS = 'FETCH_PAYMENT_OPERATIONS_REPORT_SUCCESS'
export const FETCH_PAYMENT_OPERATIONS_REPORT_FAIL = 'FETCH_PAYMENT_OPERATIONS_REPORT_FAIL'
export const FETCH_PAYMENT_OPERATIONS_REPORT_START = 'FETCH_PAYMENT_OPERATIONS_REPORT_START'
export const FETCH_PAYMENT_OPERATIONS_REPORT_RESET = 'FETCH_PAYMENT_OPERATIONS_REPORT_RESET'

//PaymentStates
export const FETCH_PAYMENT_STATES_SUCCESS = "FETCH_PAYMENT_STATES_SUCCESS"
export const FETCH_PAYMENT_STATES_FAIL = "FETCH_PAYMENT_STATES_FAIL"
export const FETCH_PAYMENT_STATES_START = "FETCH_PAYMENT_STATES_START"
export const FETCH_PAYMENT_STATES_RESET = "FETCH_PAYMENT_STATES_RESET"

// Payment Clients
// Fetch all clients
export const FETCH_PAYMENT_CLIENTS_SUCCESS = 'FETCH_PAYMENT_CLIENTS_SUCCESS'
export const FETCH_PAYMENT_CLIENTS_FAIL = 'FETCH_PAYMENT_CLIENTS_FAIL'
export const FETCH_PAYMENT_CLIENTS_START = 'FETCH_PAYMENT_CLIENTS_START'
export const FETCH_PAYMENT_CLIENTS_RESET = 'FETCH_PAYMENT_CLIENTS_RESET'
export const PAYMENT_CLIENTS_UPDATEGRID = 'PAYMENT_CLIENTS_UPDATEGRID'
export const RESET_PAYMENT_CLIENTS_PAGE = 'RESET_PAYMENT_CLIENTS_PAGE'

// Fetch client
export const FETCH_PAYMENT_CLIENT_SUCCESS = 'FETCH_PAYMENT_CLIENT_SUCCESS'
export const FETCH_PAYMENT_CLIENT_FAIL = 'FETCH_PAYMENT_CLIENT_FAIL'
export const FETCH_PAYMENT_CLIENT_START = 'FETCH_PAYMENT_CLIENT_START'


// Create client
export const CREATE_PAYMENT_CLIENT_SUCCESS = 'CREATE_PAYMENT_CLIENT_SUCCESS'
export const CREATE_PAYMENT_CLIENT_FAIL = 'CREATE_PAYMENT_CLIENT_FAIL'
export const CREATE_PAYMENT_CLIENT_START = 'CREATE_PAYMENT_CLIENT_START'
export const CREATE_PAYMENT_CLIENT_RESET = 'CREATE_PAYMENT_CLIENT_RESET'


// Update client
export const UPDATE_PAYMENT_CLIENT_SUCCESS = 'UPDATE_PAYMENT_CLIENT_SUCCESS'
export const UPDATE_PAYMENT_CLIENT_FAIL = 'UPDATE_PAYMENT_CLIENT_FAIL'
export const UPDATE_PAYMENT_CLIENT_START = 'UPDATE_PAYMENT_CLIENT_START'
export const UPDATE_PAYMENT_CLIENT_RESET = 'UPDATE_PAYMENT_CLIENT_RESET'

// Fetch Shops
export const FETCH_SHOPS_SUCCESS = 'FETCH_SHOPS_SUCCESS'
export const FETCH_SHOPS_FAIL = 'FETCH_SHOPS_FAIL'
export const FETCH_SHOPS_START = 'FETCH_SHOPS_START'
// export const UPDATE_PAYMENT_CLIENT_RESET = 'UPDATE_PAYMENT_CLIENT_RESET' 

//FETCH BANK ACCOUNTS
export const FETCH_BANK_ACCOUNTS_SUCCESS = 'FETCH_BANK_ACCOUNTS_SUCCESS'
export const FETCH_BANK_ACCOUNTS_FAIL = 'FETCH_BANK_ACCOUNTS_FAIL'
export const FETCH_BANK_ACCOUNTS_START = 'FETCH_BANK_ACCOUNTS_START'
export const FETCH_BANK_ACCOUNTS_RESET = 'FETCH_BANK_ACCOUNTS_RESET'

//FETCH CUSTODY BANK ACCOUNTS
export const FETCH_CUSTODY_BANK_ACCOUNTS_SUCCESS = 'FETCH_CUSTODY_BANK_ACCOUNTS_SUCCESS'
export const FETCH_CUSTODY_BANK_ACCOUNTS_FAIL = 'FETCH_CUSTODY_BANK_ACCOUNTS_FAIL'
export const FETCH_CUSTODY_BANK_ACCOUNTS_START = 'FETCH_CUSTODY_BANK_ACCOUNTS_START'
export const FETCH_CUSTODY_BANK_ACCOUNTS_RESET = 'FETCH_CUSTODY_BANK_ACCOUNTS_RESET'


//FETCH VENDORS
export const FETCH_VENDORS_SUCCESS = 'FETCH_VENDORS_SUCCESS'
export const FETCH_VENDORS_FAIL = 'FETCH_VENDORS_FAIL'
export const FETCH_VENDORS_START = 'FETCH_VENDORS_START'
export const FETCH_VENDORS_RESET = 'FETCH_VENDORS_RESET'

//FETCH VENDOR DETAIL
export const FETCH_VENDOR_DETAIL_SUCCESS = 'FETCH_VENDOR_DETAIL_SUCCESS'
export const FETCH_VENDOR_DETAIL_FAIL = 'FETCH_VENDOR_DETAIL_FAIL'
export const FETCH_VENDOR_DETAIL_START = 'FETCH_VENDOR_DETAIL_START'
export const FETCH_VENDOR_DETAIL_RESET = 'FETCH_VENDOR_DETAIL_RESET'

//CREATE VENDOR
export const CREATE_VENDOR_SUCCESS = 'CREATE_VENDOR_SUCCESS'
export const CREATE_VENDOR_FAIL = 'CREATE_VENDOR_FAIL'
export const CREATE_VENDOR_START = 'CREATE_VENDOR_START'
export const CREATE_VENDOR_RESET = 'CREATE_VENDOR_RESET'

//UPDATE VENDOR
export const UPDATE_VENDOR_SUCCESS = 'UPDATE_VENDOR_SUCCESS'
export const UPDATE_VENDOR_FAIL = 'UPDATE_VENDOR_FAIL'
export const UPDATE_VENDOR_START = 'UPDATE_VENDOR_START'
export const UPDATE_VENDOR_RESET = 'UPDATE_VENDOR_RESET'

//DELETE VENDOR
export const DELETE_VENDOR_SUCCESS = 'DELETE_VENDOR_SUCCESS'
export const DELETE_VENDOR_FAIL = 'DELETE_VENDOR_FAIL'
export const DELETE_VENDOR_START = 'DELETE_VENDOR_START'
export const DELETE_VENDOR_RESET = 'DELETE_VENDOR_RESET'

//FETCH MERCHANT TYPES
export const FETCH_MERCHANT_TYPES_SUCCESS = 'FETCH_MERCHANT_TYPES_SUCCESS'
export const FETCH_MERCHANT_TYPES_FAIL = 'FETCH_MERCHANT_TYPES_FAIL'
export const FETCH_MERCHANT_TYPES_START = 'FETCH_MERCHANT_TYPES_START'
export const FETCH_MERCHANT_TYPES_RESET = 'FETCH_MERCHANT_TYPES_RESET'

//FETCH  MERCHANT TYPE DETAIL
export const FETCH_MERCHANT_TYPE_DETAIL_SUCCESS = 'FETCH_MERCHANT_TYPE_DETAIL_SUCCESS'
export const FETCH_MERCHANT_TYPE_DETAIL_FAIL = 'FETCH_MERCHANT_TYPE_DETAIL_FAIL'
export const FETCH_MERCHANT_TYPE_DETAIL_START = 'FETCH_MERCHANT_TYPE_DETAIL_START'
export const FETCH_MERCHANT_TYPE_DETAIL_RESET = 'FETCH_MERCHANT_TYPE_DETAIL_RESET'

//CREATE MERCHANT TYPES
export const CREATE_MERCHANT_TYPE_SUCCESS = 'CREATE_MERCHANT_TYPE_SUCCESS'
export const CREATE_MERCHANT_TYPE_FAIL = 'CREATE_MERCHANT_TYPE_FAIL'
export const CREATE_MERCHANT_TYPE_START = 'CREATE_MERCHANT_TYPE_START'
export const CREATE_MERCHANT_TYPE_RESET = 'CREATE_MERCHANT_TYPE_RESET'

//UPDATE MERCHANT TYPES
export const UPDATE_MERCHANT_TYPE_SUCCESS = 'UPDATE_MERCHANT_TYPE_SUCCESS'
export const UPDATE_MERCHANT_TYPE_FAIL = 'UPDATE_MERCHANT_TYPE_FAIL'
export const UPDATE_MERCHANT_TYPE_START = 'UPDATE_MERCHANT_TYPE_START'
export const UPDATE_MERCHANT_TYPE_RESET = 'UPDATE_MERCHANT_TYPE_RESET'

//DELETE MERCHANT TYPES
export const DELETE_MERCHANT_TYPE_SUCCESS = 'DELETE_MERCHANT_TYPE_SUCCESS'
export const DELETE_MERCHANT_TYPE_FAIL = 'DELETE_MERCHANT_TYPE_FAIL'
export const DELETE_MERCHANT_TYPE_START = 'DELETE_MERCHANT_TYPE_START'
export const DELETE_MERCHANT_TYPE_RESET = 'DELETE_MERCHANT_TYPE_RESET'
//ALERTS
//FETCH ALERTS RECEIVED
export const FETCH_ALERT_RECEIVED_SUCCESS = 'FETCH_ALERT_RECEIVED_SUCCESS'
export const FETCH_ALERT_RECEIVED_FAIL = 'FETCH_ALERT_RECEIVED_FAIL'
export const FETCH_ALERT_RECEIVED_START = 'FETCH_ALERT_RECEIVED_START'
export const FETCH_ALERT_RECEIVED_RESET = 'FETCH_ALERT_RECEIVED_RESET'
export const ALERT_UPDATEGRID = 'ALERT_UPDATEGRID'

export const FETCH_ALERT_SUCCESS = 'FETCH_ALERT_SUCCESS'
export const FETCH_ALERT_FAIL = 'FETCH_ALERT_FAIL'
export const FETCH_ALERT_START = 'FETCH_ALERT_START'
export const FETCH_ALERT_RESET = 'FETCH_ALERT_RESET'

//RESOLVE ALERTS

export const RESOLVE_ALERT_SUCCESS = "RESOLVE_ALERT_SUCCESS"
export const RESOLVE_ALERT_START = "RESOLVE_ALERT_START"
export const RESOLVE_ALERT_FAIL = "RESOLVE_ALERT_FAIL"

//FETCH EMERGENCY PHONES
export const FETCH_EMERGENCY_PHONES_SUCCESS = 'FETCH_EMERGENCY_PHONES_SUCCESS'
export const FETCH_EMERGENCY_PHONES_FAIL = 'FETCH_EMERGENCY_PHONES_FAIL'
export const FETCH_EMERGENCY_PHONES_START = 'FETCH_EMERGENCY_PHONES_START'
export const FETCH_EMERGENCY_PHONES_RESET = 'FETCH_EMERGENCY_PHONES_RESET'

//FETCH EMERGENCY PHONE
export const FETCH_EMERGENCY_PHONE_SUCCESS = 'FETCH_EMERGENCY_PHONE_SUCCESS'
export const FETCH_EMERGENCY_PHONE_FAIL = 'FETCH_EMERGENCY_PHONE_FAIL'
export const FETCH_EMERGENCY_PHONE_START = 'FETCH_EMERGENCY_PHONE_START'
export const FETCH_EMERGENCY_PHONE_RESET = 'FETCH_EMERGENCY_PHONE_RESET'

//EDIT EMERGENCY PHONE
export const UPDATE_EMERGENCY_PHONE_SUCCESS = 'UPDATE_EMERGENCY_PHONE_SUCCESS'
export const UPDATE_EMERGENCY_PHONE_FAIL = 'UPDATE_EMERGENCY_PHONE_FAIL'
export const UPDATE_EMERGENCY_PHONE_START = 'UPDATE_EMERGENCY_PHONE_START'
export const UPDATE_EMERGENCY_PHONE_RESET = 'UPDATE_EMERGENCY_PHONE_RESET'

//CREATE EMERGENCY PHONES
export const CREATE_EMERGENCY_PHONE_SUCCESS = 'CREATE_EMERGENCY_PHONE_SUCCESS'
export const CREATE_EMERGENCY_PHONE_FAIL = 'CREATE_EMERGENCY_PHONE_FAIL'
export const CREATE_EMERGENCY_PHONE_START = 'CREATE_EMERGENCY_PHONE_START'
export const CREATE_EMERGENCY_PHONE_RESET = 'CREATE_EMERGENCY_PHONE_RESET'

//DELETE EMERGENCY PHONE
export const DELETE_EMERGENCY_PHONE_SUCCESS = 'DELETE_EMERGENCY_PHONE_SUCCESS'
export const DELETE_EMERGENCY_PHONE_FAIL = 'DELETE_EMERGENCY_PHONE_FAIL'
export const DELETE_EMERGENCY_PHONE_START = 'DELETE_EMERGENCY_PHONE_START'
export const DELETE_EMERGENCY_PHONE_RESET = 'DELETE_EMERGENCY_PHONE_RESET'

//DELETE EMERGENCY PHONES
export const DELETE_EMERGENCY_PHONES_SUCCESS = 'DELETE_EMERGENCY_PHONES_SUCCESS'
export const DELETE_EMERGENCY_PHONES_FAIL = 'DELETE_EMERGENCY_PHONES_FAIL'
export const DELETE_EMERGENCY_PHONES_START = 'DELETE_EMERGENCY_PHONES_START'
export const DELETE_EMERGENCY_PHONES_RESET = 'DELETE_EMERGENCY_PHONES_RESET'

//FETCH MESSAGES
export const FETCH_MESSAGES_SUCCESS = 'FETCH_MESSAGES_SUCCESS'
export const FETCH_MESSAGES_FAIL = 'FETCH_MESSAGES_FAIL'
export const FETCH_MESSAGES_START = 'FETCH_MESSAGES_START'
export const FETCH_MESSAGES_RESET = 'FETCH_MESSAGES_RESET'

//FETCH MESSAGE
export const FETCH_MESSAGE_SUCCESS = 'FETCH_MESSAGE_SUCCESS'
export const FETCH_MESSAGE_FAIL = 'FETCH_MESSAGE_FAIL'
export const FETCH_MESSAGE_START = 'FETCH_MESSAGE_START'
export const FETCH_MESSAGE_RESET = 'FETCH_MESSAGE_RESET'


//CREATE MESSAGE
export const CREATE_MESSAGE_SUCCESS = 'CREATE_MESSAGE_SUCCESS'
export const CREATE_MESSAGE_FAIL = 'CREATE_MESSAGE_FAIL'
export const CREATE_MESSAGE_START = 'CREATE_MESSAGE_START'
export const CREATE_MESSAGE_RESET = 'CREATE_MESSAGE_RESET'

//UPDATE MESSAGE
export const UPDATE_MESSAGE_SUCCESS = 'UPDATE_MESSAGE_SUCCESS'
export const UPDATE_MESSAGE_FAIL = 'UPDATE_MESSAGE_FAIL'
export const UPDATE_MESSAGE_START = 'UPDATE_MESSAGE_START'
export const UPDATE_MESSAGE_RESET = 'UPDATE_MESSAGE_RESET'

//DELETE MESSAGE
export const DELETE_MESSAGE_SUCCESS = 'DELETE_MESSAGE_SUCCESS'
export const DELETE_MESSAGE_FAIL = 'DELETE_MESSAGE_FAIL'
export const DELETE_MESSAGE_START = 'DELETE_MESSAGE_START'
export const DELETE_MESSAGE_RESET = 'DELETE_MESSAGE_RESET'


//FETCH ALERT MOTIVES
export const FETCH_ALERT_MOTIVES_SUCCESS = 'FETCH_ALERT_MOTIVES_SUCCESS'
export const FETCH_ALERT_MOTIVES_FAIL = 'FETCH_ALERT_MOTIVES_FAIL'
export const FETCH_ALERT_MOTIVES_START = 'FETCH_ALERT_MOTIVES_START'
export const FETCH_ALERT_MOTIVES_RESET = 'FETCH_ALERT_MOTIVES_RESET'

//ACTIVATE ALERT MOTIVE
export const ACTIVATE_ALERT_MOTIVE_SUCCESS = 'ACTIVATE_ALERT_MOTIVE_SUCCESS'
export const ACTIVATE_ALERT_MOTIVE_FAIL = 'ACTIVATE_ALERT_MOTIVE_FAIL'
export const ACTIVATE_ALERT_MOTIVE_START = 'ACTIVATE_ALERT_MOTIVE_START'
export const ACTIVATE_ALERT_MOTIVE_RESET = 'ACTIVATE_ALERT_MOTIVE_RESET'

//FETCH NOTIFICATION TYPES
export const FETCH_NOTIFICATION_TYPES_SUCCESS = 'FETCH_NOTIFICATION_TYPES_SUCCESS'
export const FETCH_NOTIFICATION_TYPES_FAIL = 'FETCH_NOTIFICATION_TYPES_FAIL'
export const FETCH_NOTIFICATION_TYPES_START = 'FETCH_NOTIFICATION_TYPES_START'

// BCRA regulations
//FETCH PROCESSES
export const FETCH_PROCESSES_SUCCESS = 'FETCH_PROCESSES_SUCCESS'
export const FETCH_PROCESSES_FAIL = 'FETCH_PROCESSES_FAIL'
export const FETCH_PROCESSES_START = 'FETCH_PROCESSES_START'
export const PROCESSES_UPDATE_BCRA_GRID = 'PROCESSES_UPDATE_BCRA_GRID'

//FETCH PROCESS
export const FETCH_PROCESS_SUCCESS = 'FETCH_PROCESS_SUCCESS'
export const FETCH_PROCESS_ERROR_SUCCESS = 'FETCH_PROCESS_ERROR_SUCCESS'
export const FETCH_PROCESS_FAIL = 'FETCH_PROCESS_FAIL'
export const FETCH_PROCESS_START = 'FETCH_PROCESS_START'

//FETCH PROCESS
export const IMPORT_FILE_SUCCESS = 'IMPORT_FILE_SUCCESS'
export const IMPORT_FILE_FAIL = 'IMPORT_FILE_FAIL'
export const IMPORT_FILE_START = 'IMPORT_FILE_START'

export const SELECT_ROWS_ALERTS = "SELECT_ROWS_ALERTS";
export const SELECT_ROWS_EMERGENCY_PHONES = "SELECT_ROWS_EMERGENCY_PHONES"


//COMMUNITTIES
export const FETCH_COMMUNITIES_SUCCESS = 'FETCH_ALL_COMMUNITIES_SUCCESS'
export const FETCH_COMMUNITIES_FAIL = 'FETCH_ALL_COMMUNITIES_FAIL'
export const FETCH_COMMUNITIES_START = 'FETCH_ALL_COMMUNITIES_START'
export const FETCH_COMMUNITIES_RESET = 'FETCH_ALL_COMMUNITIES_RESET'

export const UPDATE_COMMUNITIES_GRID = 'UPDATE_COMMUNITIES_GRID'

//COMMUNITTIES
export const FETCH_COMMUNITY_SUCCESS = 'FETCH_COMMUNITY_SUCCESS'
export const FETCH_COMMUNITY_FAIL = 'FETCH_COMMUNITY_FAIL'
export const FETCH_COMMUNITY_START = 'FETCH_COMMUNITY_START'
export const FETCH_COMMUNITY_RESET = 'FETCH_COMMUNITY_RESET'

//FETCH COMMUNITY STATES
export const FETCH_COMMUNITY_STATES_SUCCESS = 'FETCH_COMMUNITY_STATES_SUCCESS'
export const FETCH_COMMUNITY_STATES_FAIL = 'FETCH_COMMUNITY_STATES_FAIL'
export const FETCH_COMMUNITY_STATES_START = 'FETCH_COMMUNITY_STATES_START'
export const FETCH_COMMUNITY_STATES_RESET = 'FETCH_COMMUNITY_STATES_RESET'

//FETCH COMMUNITY ACTIVATE
export const ACTIVATE_COMMUNITY_SUCCESS = 'ACTIVATE_COMMUNITY_SUCCESS'
export const ACTIVATE_COMMUNITY_FAIL = 'ACTIVATE_COMMUNITY_FAIL'
export const ACTIVATE_COMMUNITY_START = 'ACTIVATE_COMMUNITY_START'
export const ACTIVATE_COMMUNITY_RESET = 'ACTIVATE_COMMUNITY_RESET'

export const CLEAR_COMMUNITIES_PAGE = 'CLEAR_COMMUNITIES_PAGE'


export const FETCH_NOTIFICATION_REQUEST_FORMAT_SUCCESS = 'FETCH_NOTIFICATION_REQUEST_FORMAT_SUCCESS'
export const FETCH_NOTIFICATION_REQUEST_FORMAT_FAIL = 'FETCH_NOTIFICATION_REQUEST_FORMAT_FAIL'
export const FETCH_NOTIFICATION_REQUEST_FORMAT_START = 'FETCH_NOTIFICATION_REQUEST_FORMAT_START'

export const FETCH_PAYMENT_METHOD_DETAIL_FAIL = 'FETCH_PAYMENT_METHOD_DETAIL_FAIL'
export const FETCH_PAYMENT_METHOD_DETAIL_START = 'FETCH_PAYMENT_METHOD_DETAIL_START'
export const FETCH_PAYMENT_METHOD_DETAIL_SUCCESS = 'FETCH_PAYMENT_METHOD_DETAIL_SUCCESS'

export const FETCH_CANCEL_OPERATION_FAIL = 'FETCH_CANCEL_OPERATION_FAIL '
export const FETCH_CANCEL_OPERATION_START = 'FETCH_CANCEL_OPERATION_START '
export const FETCH_CANCEL_OPERATION_SUCCESS = 'FETCH_CANCEL_OPERATION_SUCCESS'

//INVITADOS 

export const FETCH_INVITADOS_START = 'FETCH_INVITADOS_START '
export const FETCH_INVITADOS_FAIL = 'FETCH_INVITADOS_FAIL '
export const FETCH_INVITADOS_SUCCESS = 'FETCH_INVITADOS_SUCCESS'

//BOOKINGS 
export const SET_SELECTED_NEIGHBOURHOOD = 'SET_SELECTED_NEIGHBOURHOOD '
export const FETCH_BOOKING_SPACES_START = 'FETCH_BOOKING_SPACES_START '
export const FETCH_BOOKING_SPACES_FAIL = 'FETCH_BOOKING_SPACES_FAIL '
export const FETCH_BOOKING_SPACES_SUCCESS = 'FETCH_BOOKING_SPACES_SUCCESS'
export const FETCH_COMBO_SPACE_TO_BLOCK_START = 'FETCH_COMBO_SPACE_TO_BLOCK_START '
export const FETCH_COMBO_SPACE_TO_BLOCK_FAIL = 'FETCH_COMBO_SPACE_TO_BLOCK_FAIL '
export const FETCH_COMBO_SPACE_TO_BLOCK_SUCCESS = 'FETCH_COMBO_SPACE_TO_BLOCK_SUCCESS'
export const FETCH_COMBO_SPACE_TO_BLOCK_RESET = 'FETCH_COMBO_SPACE_TO_BLOCK_RESET'
export const FETCH_COMBO_ACTIVITIES_START = 'FETCH_COMBO_ACTIVITIES_START '
export const FETCH_COMBO_ACTIVITIES_FAIL = 'FETCH_COMBO_ACTIVITIES_FAIL '
export const FETCH_COMBO_ACTIVITIES_SUCCESS = 'FETCH_COMBO_ACTIVITIES_SUCCESS'
export const UPDATE_BOOKING_SPACE_FILTERS = 'UPDATE_BOOKING_SPACE_FILTERS'
export const CLEAR_BOOKING_SPACES = 'CLEAR_BOOKING_SPACES'
export const FETCH_BOOKING_ACTIVITIES_START = 'FETCH_BOOKING_ACTIVITIES_START'
export const FETCH_BOOKING_ACTIVITIES_FAIL = 'FETCH_BOOKING_ACTIVITIES_FAIL'
export const FETCH_BOOKING_ACTIVITIES_SUCCESS = 'FETCH_BOOKING_ACTIVITIES_SUCCESS'
export const FETCH_SCHEDULES_CALENDAR_START = 'FETCH_SCHEDULES_CALENDAR_START'
export const FETCH_SCHEDULES_CALENDAR_FAIL = 'FETCH_SCHEDULES_CALENDAR_FAIL'
export const FETCH_SCHEDULES_CALENDAR_SUCCESS = 'FETCH_SCHEDULES_CALENDAR_SUCCESS'
export const FETCH_SCHEDULES_BOOKINGS_START = 'FETCH_SCHEDULES_BOOKINGS_START'
export const FETCH_SCHEDULES_BOOKINGS_FAIL = 'FETCH_SCHEDULES_BOOKINGS_FAIL'
export const FETCH_SCHEDULES_BOOKINGS_SUCCESS = 'FETCH_SCHEDULES_BOOKINGS_SUCCESS'
export const UPDATE_SELECTED_CALENDAR_DATE = 'UPDATE_SELECTED_CALENDAR_DATE'
export const UPDATE_BOOKING_ACTIVITIES_FILTERS = 'UPDATE_BOOKING_ACTIVITIES_FILTERS'
export const FETCH_BOOKING_ACTIVITY_START = 'FETCH_BOOKING_ACTIVITY_START'
export const FETCH_BOOKING_ACTIVITY_FAIL = 'FETCH_BOOKING_ACTIVITY_FAIL'
export const FETCH_BOOKING_ACTIVITY_SUCCESS = 'FETCH_BOOKING_ACTIVITY_SUCCESS'
export const CREATE_BOOKING_ACTIVITY_SUCCESS = 'CREATE_BOOKING_ACTIVITY_SUCCESS'
export const CREATE_BOOKING_ACTIVITY_FAIL = 'CREATE_BOOKING_ACTIVITY_FAIL'
export const CREATE_BOOKING_ACTIVITY_START = 'CREATE_BOOKING_ACTIVITY_START'
export const CREATE_BOOKING_ACTIVITY_RESET = 'CREATE_BOOKING_ACTIVITY_RESET'
export const DELETE_BOOKING_ACTIVITY_SUCCESS = 'DELETE_BOOKING_ACTIVITY_SUCCESS'
export const DELETE_BOOKING_ACTIVITY_FAIL = 'DELETE_BOOKING_ACTIVITY_FAIL'
export const DELETE_BOOKING_ACTIVITY_START = 'DELETE_BOOKING_ACTIVITY_START'
export const DELETE_BOOKING_ACTIVITY_RESET = 'DELETE_BOOKING_ACTIVITY_RESET'
export const UPDATE_BOOKING_ACTIVITY_START = 'UPDATE_BOOKING_ACTIVITY_START'
export const UPDATE_BOOKING_ACTIVITY_FAIL = 'UPDATE_BOOKING_ACTIVITY_FAIL'
export const UPDATE_BOOKING_ACTIVITY_SUCCESS = 'UPDATE_BOOKING_ACTIVITY_SUCCESS'
export const UPDATE_BOOKING_ACTIVITY_RESET = 'UPDATE_BOOKING_ACTIVITY_RESET'
export const CREATE_BOOKING_NEIGHBOURHOOD_START = 'CREATE_BOOKING_NEIGHBOURHOOD_START'
export const CREATE_BOOKING_NEIGHBOURHOOD_SUCCESS = 'CREATE_BOOKING_NEIGHBOURHOOD_SUCCESS'
export const CREATE_BOOKING_NEIGHBOURHOOD_FAIL = 'CREATE_BOOKING_NEIGHBOURHOOD_FAIL'
export const CREATE_BOOKING_NEIGHBOURHOOD_RESET = 'CREATE_BOOKING_NEIGHBOURHOOD_RESET'


export const CANCEL_BOOKING_START = 'CANCEL_BOOKING_START'
export const CANCEL_BOOKING_SUCCESS = 'CANCEL_BOOKING_SUCCESS'
export const CANCEL_BOOKING_FAIL = 'CANCEL_BOOKING_FAIL'
export const CANCEL_BOOKING_RESET = 'CANCEL_BOOKING_RESET'

export const FETCH_NEIGHBOURHOOD_SPACE_DETAIL_START = 'FETCH_NEIGHBOURHOOD_SPACE_DETAIL_START'
export const FETCH_NEIGHBOURHOOD_SPACE_DETAIL_FAIL = 'FETCH_NEIGHBOURHOOD_SPACE_DETAIL_FAIL'
export const FETCH_NEIGHBOURHOOD_SPACE_DETAIL_SUCCESS = 'FETCH_NEIGHBOURHOOD_SPACE_DETAIL_SUCCESS'
export const CREATE_NEIGHBOURHOOD_SPACE_START = 'CREATE_NEIGHBOURHOOD_SPACE_START'
export const CREATE_NEIGHBOURHOOD_SPACE_FAIL = 'CREATE_NEIGHBOURHOOD_SPACE_FAIL'
export const CREATE_NEIGHBOURHOOD_SPACE_SUCCESS = 'CREATE_NEIGHBOURHOOD_SPACE_SUCCESS'
export const CREATE_NEIGHBOURHOOD_SPACE_RESET = 'CREATE_NEIGHBOURHOOD_SPACE_RESET'
export const UPDATE_NEIGHBOURHOOD_SPACE_START = 'UPDATE_NEIGHBOURHOOD_SPACE_START'
export const UPDATE_NEIGHBOURHOOD_SPACE_FAIL = 'UPDATE_NEIGHBOURHOOD_SPACE_FAIL'
export const UPDATE_NEIGHBOURHOOD_SPACE_SUCCESS = 'UPDATE_NEIGHBOURHOOD_SPACE_SUCCESS'
export const UPDATE_NEIGHBOURHOOD_SPACE_RESET = 'UPDATE_NEIGHBOURHOOD_SPACE_RESET'
export const DELETE_NEIGHBOURHOOD_SPACE_START = 'DELETE_NEIGHBOURHOOD_SPACE_START'
export const DELETE_NEIGHBOURHOOD_SPACE_FAIL = 'DELETE_NEIGHBOURHOOD_SPACE_FAIL'
export const DELETE_NEIGHBOURHOOD_SPACE_SUCCESS = 'DELETE_NEIGHBOURHOOD_SPACE_SUCCESS'
export const DELETE_NEIGHBOURHOOD_SPACE_RESET = 'DELETE_NEIGHBOURHOOD_SPACE_RESET'
export const FETCH_BOOKINGS_START = 'FETCH_BOOKINGS_START'
export const FETCH_BOOKINGS_FAIL = 'FETCH_BOOKINGS_FAIL'
export const FETCH_BOOKINGS_SUCCESS = 'FETCH_BOOKINGS_SUCCESS'
export const FETCH_EXPORT_BOOKINGS_START = 'FETCH_EXPORT_BOOKINGS_START'
export const FETCH_EXPORT_BOOKINGS_FAIL = 'FETCH_EXPORt_BOOKINGS_FAIL'
export const FETCH_EXPORT_BOOKINGS_SUCCESS = 'FETCH_EXPORT_BOOKINGS_SUCCESS'
export const FETCH_EXPORT_BOOKINGS_RESET = 'FETCH_EXPORT_BOOKINGS_RESET'
export const CLEAR_BOOKINGS_GRID = 'CLEAR_BOOKINGS_GRID'
export const UPDATE_BOOKINGS_GRID = 'UPDATE_BOOKINGS_GRID'
export const CREATE_SCHEDULE_START = 'CREATE_SCHEDULE_START'
export const CREATE_SCHEDULE_FAIL = 'CREATE_SCHEDULE_FAIL'
export const CREATE_SCHEDULE_SUCCESS = 'CREATE_SCHEDULE_SUCCESS'
export const CREATE_SCHEDULE_RESET = 'CREATE_SCHEDULE_RESET'
export const UPDATE_SCHEDULE_START = 'UPDATE_SCHEDULE_START'
export const UPDATE_SCHEDULE_FAIL = 'UPDATE_SCHEDULE_FAIL'
export const UPDATE_SCHEDULE_SUCCESS = 'UPDATE_SCHEDULE_SUCCESS'
export const UPDATE_SCHEDULE_RESET = 'UPDATE_SCHEDULE_RESET'
export const FETCH_SCHEDULE_DETAIL_START = 'FETCH_SCHEDULE_DETAIL_START'
export const FETCH_SCHEDULE_DETAIL_FAIL = 'FETCH_SCHEDULE_DETAIL_FAIL'
export const FETCH_SCHEDULE_DETAIL_SUCCESS = 'FETCH_SCHEDULE_DETAIL_SUCCESS'
export const FETCH_SCHEDULE_DETAIL_RESET = 'FETCH_SCHEDULE_DETAIL_RESET'
export const CREATE_TYC_SUCCESS = 'CREATE_TYC_SUCCESS'
export const CREATE_TYC_FAIL = 'CREATE_TYC_FAIL'
export const CREATE_TYC_START = 'CREATE_TYC_START'
export const CREATE_TYC_RESET = 'CREATE_TYC_RESET'
export const FETCH_RULES_SUCCESS = 'FETCH_RULES_SUCCESS'
export const FETCH_RULES_FAIL = 'FETCH_RULES_FAIL'
export const FETCH_RULES_START = 'FETCH_RULES_START'
export const FETCH_RULES_RESET = 'FETCH_RULES_RESET'
export const FETCH_RULE_VALUES_SUCCESS = 'FETCH_RULE_VALUES_SUCCESS'
export const FETCH_RULE_VALUES_FAIL = 'FETCH_RULE_VALUES_FAIL'
export const FETCH_RULE_VALUES_START = 'FETCH_RULE_VALUES_START'
export const FETCH_RULE_VALUES_RESET = 'FETCH_RULE_VALUES_RESET'
export const UPDATE_RULE_VALUE_SUCCESS = 'UPDATE_RULE_VALUES_SUCCESS'
export const UPDATE_RULE_VALUE_FAIL = 'UPDATE_RULE_VALUES_FAIL'
export const UPDATE_RULE_VALUE_START = 'UPDATE_RULE_VALUES_START'
export const UPDATE_RULE_VALUE_RESET = 'UPDATE_RULE_VALUES_RESET'
export const UPDATE_CURRENT_RULE_VALUE = 'UPDATE_CURRENT_RULE_VALUE'
export const DELETE_RULE_VALUE_SUCCESS = 'DELETE_RULE_VALUE_SUCCESS'
export const DELETE_RULE_VALUE_FAIL = 'DELETE_RULE_VALUE_FAIL'
export const DELETE_RULE_VALUE_START = 'DELETE_RULE_VALUE_START'
export const DELETE_RULE_VALUE_RESET = 'DELETE_RULE_VALUE_RESET'
export const CREATE_RULE_VALUES_SUCCESS = 'CREATE_RULE_VALUES_SUCCESS'
export const CREATE_RULE_VALUES_FAIL = 'CREATE_RULE_VALUES_FAIL'
export const CREATE_RULE_VALUES_START = 'CREATE_RULE_VALUES_START'
export const CREATE_RULE_VALUES_RESET = 'CREATE_RULE_VALUES_RESET'
export const FETCH_MOVEMENTS_LAST_24_HOURS_REPORT_SUCCESS = 'FETCH_MOVEMENTS_LAST_24_HOURS_REPORT_SUCCESS'
export const FETCH_MOVEMENTS_LAST_24_HOURS_REPORT_FAIL = 'FETCH_MOVEMENTS_LAST_24_HOURS_REPORT_FAIL'
export const FETCH_MOVEMENTS_LAST_24_HOURS_REPORT_START = 'FETCH_MOVEMENTS_LAST_24_HOURS_REPORT_START'
export const FETCH_MOVEMENTS_LAST_24_HOURS_REPORT_RESET = 'FETCH_MOVEMENTS_LAST_24_HOURS_REPORT_RESET'

export const FETCH_MOVEMENTS_LAST_7_DAYS_REPORT_SUCCESS = 'FETCH_MOVEMENTS_LAST_7_DAYS_REPORT_SUCCESS'
export const FETCH_MOVEMENTS_LAST_7_DAYS_REPORT_FAIL = 'FETCH_MOVEMENTS_LAST_7_DAYS_REPORT_FAIL'
export const FETCH_MOVEMENTS_LAST_7_DAYS_REPORT_START = 'FETCH_MOVEMENTS_LAST_7_DAYS_REPORT_START'
export const FETCH_MOVEMENTS_LAST_7_DAYS_REPORT_RESET = 'FETCH_MOVEMENTS_LAST_7_DAYS_REPORT_RESET'
