// Auth
export const authAPI =
  "https://ogey57noad.execute-api.us-east-2.amazonaws.com/dev/api/authentication/administration/login";
export const refreshTokenUrl =
  "https://ogey57noad.execute-api.us-east-2.amazonaws.com/dev/api/authentication/administration/refresh-token";
export const forgotPasword =
  "https://ogey57noad.execute-api.us-east-2.amazonaws.com/dev/api/authentication/administration/forgot-password/";
export const forgotPaswordConfirmation =
  "https://ogey57noad.execute-api.us-east-2.amazonaws.com/dev/api/authentication/administration/forgot-password/confirmation";
export const authTokenAPI =
  "https://ogey57noad.execute-api.us-east-2.amazonaws.com/dev/api/authentication/administration/login";

//USED IN "mensajes"
export const getRecibedMsjAPI =
  "https://mr7d0t06z6.execute-api.us-east-2.amazonaws.com/dev/api/Feed/";
export const getSentMsjAPI =
  "https://mr7d0t06z6.execute-api.us-east-2.amazonaws.com/dev/api/MessagePrivate/sent";
export const getUserNamesAPI =
  "https://mr7d0t06z6.execute-api.us-east-2.amazonaws.com/dev/api/xperson_neighbourhood/neighbourhood";
export const postPrivateMsjAPI =
  "https://mr7d0t06z6.execute-api.us-east-2.amazonaws.com/dev/api/MessagePrivate";
export const postPublicMsjAPI =
  "https://mr7d0t06z6.execute-api.us-east-2.amazonaws.com/dev/api/MessagePrivate/public";
export const getUnreadMsjAPI =
  "https://mr7d0t06z6.execute-api.us-east-2.amazonaws.com/dev/api/MessagePrivate/received/unread";

//USED IN "familiares"
export const getFamiliesByPropietaryIdAPI =
  "https://63luqij71h.execute-api.us-east-2.amazonaws.com/dev/api/masterdata/families/propietario/";
//USED IN "vehiculos"
export const getVehicleByPropietaryIdAPI =
  "https://63luqij71h.execute-api.us-east-2.amazonaws.com/dev/api/vehicle/user/";

//USED IN "feed"
export const getEventListAPI =
  "https://mr7d0t06z6.execute-api.us-east-2.amazonaws.com/dev/api/Event/";
export const getNewsListAPI =
  "https://mr7d0t06z6.execute-api.us-east-2.amazonaws.com/dev/api/NewsArticle/";
export const postNewEventAPI =
  "https://mr7d0t06z6.execute-api.us-east-2.amazonaws.com/dev/api/Event/";
export const postNewArticleAPI =
  "https://mr7d0t06z6.execute-api.us-east-2.amazonaws.com/dev/api/NewsArticle/";
export const preSubmitFilesAPI =
  "https://mr7d0t06z6.execute-api.us-east-2.amazonaws.com/dev/api/File";
export const deleteArticleAPI =
  "https://mr7d0t06z6.execute-api.us-east-2.amazonaws.com/dev/api/NewsArticle/";
export const deleteEventAPI =
  "https://mr7d0t06z6.execute-api.us-east-2.amazonaws.com/dev/api/Event/";

export const editEventAPI = "todo:api";

//USED IN "lotes"
export const getFunctionalUnitAPI =
  "https://63luqij71h.execute-api.us-east-2.amazonaws.com/dev/api/masterdata/functional-units/neighbourhood";

//USED IN "padron"
export const postNewProprietaryAPI =
  "https://63luqij71h.execute-api.us-east-2.amazonaws.com/dev/api/person/propietary";
export const postImportMasiveProprietariesAPI =
  "https://63luqij71h.execute-api.us-east-2.amazonaws.com/dev/api/person/import-propietary";
export const getSearchedPadronAPI =
  "https://63luqij71h.execute-api.us-east-2.amazonaws.com/dev/api/Propietario/search/";
export const getPadronAPI =
  "https://63luqij71h.execute-api.us-east-2.amazonaws.com/dev/api/person/searchPropietary";
export const editPadronAPI =
  "https://63luqij71h.execute-api.us-east-2.amazonaws.com/dev/api/person";

//USED IN "proveedores"
export const getCategoriesAPI =
  "https://1fmtsyg4og.execute-api.us-east-2.amazonaws.com/Staging/api/categories";
export const getSuppliersAPI =
  "https://1fmtsyg4og.execute-api.us-east-2.amazonaws.com/Staging/api/suppliers";
export const getServicesAPI =
  "https://1fmtsyg4og.execute-api.us-east-2.amazonaws.com/Staging/api/services/categoryId";
export const getNeighbourhoodAPI =
  "https://63luqij71h.execute-api.us-east-2.amazonaws.com/dev/api/masterdata/neighbourhoods";
export const postNewSupplier =
  "https://1fmtsyg4og.execute-api.us-east-2.amazonaws.com/Staging/api/suppliers";
export const postNewCategory =
  "https://1fmtsyg4og.execute-api.us-east-2.amazonaws.com/Staging/api/categories";
export const postNewService =
  "https://1fmtsyg4og.execute-api.us-east-2.amazonaws.com/Staging/api/services/categoryId";
export const getSuppliersRequestAPI =
  "https://1fmtsyg4og.execute-api.us-east-2.amazonaws.com/Staging/api/request";

// //USED IN "reservas"
// export const activitiesAPI = 'https://1an8i0xmil.execute-api.us-east-2.amazonaws.com/Staging/api/activities'
// export const getSpacesAPI = 'https://1an8i0xmil.execute-api.us-east-2.amazonaws.com/Staging/api/spaces/actividadBarrioId'
// export const postNewSpaceAPI = 'https://1an8i0xmil.execute-api.us-east-2.amazonaws.com/Staging/api/active_time'
// export const postNewExceptionsAPI = 'https://1an8i0xmil.execute-api.us-east-2.amazonaws.com/Staging/api/exception-spaces'
// export const getBookingRequestAPI = 'https://1an8i0xmil.execute-api.us-east-2.amazonaws.com/Staging/api/booking/future'
// export const delSpaceAPI = `https://1an8i0xmil.execute-api.us-east-2.amazonaws.com/Staging/api/Space`
// export const deleteActivityAPI = 'https://1an8i0xmil.execute-api.us-east-2.amazonaws.com/Staging/api/activities'
// export const deleteReservationAPI = 'todo-api-delete-reservation'
// //  export const generateBookingAPI = 'https://1an8i0xmil.execute-api.us-east-2.amazonaws.com/Staging/api/booking'
// export const getNoOwnersAPI = 'https://1an8i0xmil.execute-api.us-east-2.amazonaws.com/Staging/api/person/get-no-owners'
// export const generateBookingAPI ='https://1an8i0xmil.execute-api.us-east-2.amazonaws.com/Staging/api/booking'

//USED IN "reservas" No tiene ambiente de dev
export const activitiesAPI =
  "https://1an8i0xmil.execute-api.us-east-2.amazonaws.com/Staging/api/activities";
export const getSpacesAPI =
  "https://1an8i0xmil.execute-api.us-east-2.amazonaws.com/Staging/api/spaces/actividadBarrioId";
export const postNewSpaceAPI =
  "https://1an8i0xmil.execute-api.us-east-2.amazonaws.com/Staging/api/active_time";
export const postNewExceptionsAPI =
  "https://1an8i0xmil.execute-api.us-east-2.amazonaws.com/Staging/api/exception-spaces";
export const getBookingRequestAPI =
  "https://1an8i0xmil.execute-api.us-east-2.amazonaws.com/Staging/api/booking/future";
export const delSpaceAPI = `https://1an8i0xmil.execute-api.us-east-2.amazonaws.com/Staging/api/Space`;
export const deleteActivityAPI =
  "https://1an8i0xmil.execute-api.us-east-2.amazonaws.com/Staging/api/activities";
export const deleteReservationAPI = "todo-api-delete-reservation";
export const generateBookingAPI =
  "https://1an8i0xmil.execute-api.us-east-2.amazonaws.com/Staging/api/booking";

//USED IN "securityStaff"
export const postNewSecurityStaffAPI =
  "https://q6b5px90od.execute-api.us-east-2.amazonaws.com/Staging/api/user/";
export const getSearchedSecurityStaffListAPI =
  "https://ogey57noad.execute-api.us-east-2.amazonaws.com/dev/api/authentication/security/user/search?name=";
export const getSecurityStaffListAPI =
  "https://ogey57noad.execute-api.us-east-2.amazonaws.com/dev/api/authentication/security/user/search";

//USED IN "Accesos"
export const fetchLotsAPI =
  "https://63luqij71h.execute-api.us-east-2.amazonaws.com/dev/api/masterdata/functional-units/neighbourhood";

//USED IN "invitados"

export const getInvitationsAPI =
  "https://ytn7kc6b3i.execute-api.us-east-2.amazonaws.com/dev/api/event/neighbourhoodadmin/";
export const getGuestsAPI =
  "https://ytn7kc6b3i.execute-api.us-east-2.amazonaws.com/dev/api/guest_frequency/username/";
export const getFilteredGuestsAPI =
  "https://ytn7kc6b3i.execute-api.us-east-2.amazonaws.com/dev/api/guests/today-invites/search";

//USED IN "neighbourhood"
export const getDefaultActivities =
  "https://1an8i0xmil.execute-api.us-east-2.amazonaws.com/Staging/api/activity";
export const getDefaultCategories = "";

// USED IN "ActiveTimes"
export const activeTimesAPI =
  "https://1an8i0xmil.execute-api.us-east-2.amazonaws.com/Staging/api/active_time/space";

// USED IN "Billetera"
export const getAccountWithAliasAPI =
  "https://pw5rezsgyj.execute-api.us-east-2.amazonaws.com/dev/api/bank-account/get-account?alias=";
export const getAccountWithUniqueKeyAPI =
  "https://pw5rezsgyj.execute-api.us-east-2.amazonaws.com/dev/api/bank-account/get-account?uniqueKey=";
export const getAccountWithLoteAPI =
  "https://pw5rezsgyj.execute-api.us-east-2.amazonaws.com/dev/api/bank-account/get-account?propertyCode=";
export const postRequestedTransactionAPI =
  "https://pw5rezsgyj.execute-api.us-east-2.amazonaws.com/dev/api/requested-transaction?";
export const getCodeQRImageAPI =
  "https://pw5rezsgyj.execute-api.us-east-2.amazonaws.com/dev/api/requested-transaction/generate-qr/";
export const getRegenerateOpenQR =
  "https://pw5rezsgyj.execute-api.us-east-2.amazonaws.com/dev/api/requested-transaction/regenerate-openqr/";
export const getTransactionUserAPI =
  "https://pw5rezsgyj.execute-api.us-east-2.amazonaws.com/dev/api/transaction/neighbourhood/";
export const getBalanceUserAPI =
  "https://pw5rezsgyj.execute-api.us-east-2.amazonaws.com/dev/api/transaction/neighbourhood/balance";
export const postCreateTransactionRequestAPI =
  "https://pw5rezsgyj.execute-api.us-east-2.amazonaws.com/dev/api/transfer/create-transfer-request";
export const getDebitAccountInfo =
  "https://pw5rezsgyj.execute-api.us-east-2.amazonaws.com/dev/api/bank-account/get-neighbourhood-account";
export const getTransferPendingAPI =
  "https://pw5rezsgyj.execute-api.us-east-2.amazonaws.com/dev/api/transfer/pendings-auth";
export const TransactionChargeWithCardAPI =
  "https://pw5rezsgyj.execute-api.us-east-2.amazonaws.com/dev/api/transaction/pay-transaction";
export const getTransactionDataAPI =
  "https://pw5rezsgyj.execute-api.us-east-2.amazonaws.com/dev/api/transaction/";
export const getNumberOfTransferPendingAPI =
  "https://pw5rezsgyj.execute-api.us-east-2.amazonaws.com/dev/api/transfer/total-pendings-auth";
export const getApproveTransferAPI =
  "https://pw5rezsgyj.execute-api.us-east-2.amazonaws.com/dev/api/transfer/approve-transfer-workflow";
export const getApproveTransferWithTokenAPI =
  "https://pw5rezsgyj.execute-api.us-east-2.amazonaws.com/dev/api/transfer/approve-transfer-workflow?Token=";
export const getDeniedTransferAPI =
  "https://pw5rezsgyj.execute-api.us-east-2.amazonaws.com/dev/api/transfer/reject-transfer-workflow";
export const postReSendToken =
  "https://pw5rezsgyj.execute-api.us-east-2.amazonaws.com/dev/api/token/sms/send";
export const getAccountQR =
  "https://pw5rezsgyj.execute-api.us-east-2.amazonaws.com/dev/api/requested-transaction/search-person";
export const getDebitAccountsInfo =
  "https://pw5rezsgyj.execute-api.us-east-2.amazonaws.com/dev/api/bank-account/get-neighbourhood-accounts";
export const getTokenPayMi =
  "https://43naw9gjpa.execute-api.us-east-2.amazonaws.com/dev/api/paymentgateway/client/client-token";
export const generatePaymentPayMi =
  "https://43naw9gjpa.execute-api.us-east-2.amazonaws.com/dev/api/paymentgateway/payment/create";
export const getDownloadsummary =
  "https://pw5rezsgyj.execute-api.us-east-2.amazonaws.com/dev/api/client/download-statement?";
export const lastMovementsAPI =
  "https://pw5rezsgyj.execute-api.us-east-2.amazonaws.com/dev/api/transaction/movements";

// USED IN "Expensas"
export const postGenerateExpensesAPI =
  "https://pw5rezsgyj.execute-api.us-east-2.amazonaws.com/dev/api/expense/generate-manual-expense";
export const postGenerateAutomaticExpensesAPI =
  "https://pw5rezsgyj.execute-api.us-east-2.amazonaws.com/dev/api/expense/generate-automatic-expense";
export const getHomeExpenses =
  "https://pw5rezsgyj.execute-api.us-east-2.amazonaws.com/dev/api/expense/search?";
export const getExpensesPorCliente =
  "https://pw5rezsgyj.execute-api.us-east-2.amazonaws.com/dev/api/expense/client?ClientId=";
export const getFileDetailClient =
  "https://pw5rezsgyj.execute-api.us-east-2.amazonaws.com/dev/api/expense/export/detail/";
export const getFileDetailNeighbourhood =
  "https://pw5rezsgyj.execute-api.us-east-2.amazonaws.com/dev/api/expense/export/overview?NeighborhoodGuid=";
export const postUploadFile =
  "https://pw5rezsgyj.execute-api.us-east-2.amazonaws.com/dev/api/expense/process-expense-result-manual";
export const postExecuteExpensesReportAPI =
  "https://0xtih6e5g9.execute-api.us-east-2.amazonaws.com/Staging"; // STAGING
// 'https://9c2jbeu320.execute-api.us-east-2.amazonaws.com/prod' // PRODUCTION
export const getOrdersAPI =
  "https://pw5rezsgyj.execute-api.us-east-2.amazonaws.com/dev/api/expense/search-debit-reports?page=";

// USED IN "Profile"
export const getRoles = "https://63luqij71h.execute-api.us-east-2.amazonaws.com/dev/api/role";
export const getMenus = "https://63luqij71h.execute-api.us-east-2.amazonaws.com/dev/api/menu";
export const getPersonByNeighbourhood =
  "https://63luqij71h.execute-api.us-east-2.amazonaws.com/dev/api/person/neighbourhood";
export const fetchRefreshMenusForRoleAPI =
  "https://63luqij71h.execute-api.us-east-2.amazonaws.com/dev/api/person/menus";

// USED IN "Accesos"
export const fetchGetGuestAPI =
  "https://ytn7kc6b3i.execute-api.us-east-2.amazonaws.com/dev/api/invites/"; //(Get Guest)
export const fetchGetVehicleBrandsAPI =
  "https://ytn7kc6b3i.execute-api.us-east-2.amazonaws.com/dev/api/vehicle_brand";
export const fetchGetVehicleModelsAPI =
  "https://ytn7kc6b3i.execute-api.us-east-2.amazonaws.com/dev/api/vehicle_model/";
export const fetchNotificationsAPI = "http://localhost/1";
export const fetchMensajeriaAPI = "http://localhost/2";
export const fetchRegistrosAPI =
  "https://ytn7kc6b3i.execute-api.us-east-2.amazonaws.com/dev/api/invites/states";
export const getTicketCategoriesAPI =
  "https://xn4ait34q9.execute-api.us-east-2.amazonaws.com/dev/api/category/";

// USED IN "Tickets"
export const getCategoriesByRoleAPI =
  "https://xn4ait34q9.execute-api.us-east-2.amazonaws.com/dev/api/category/getcategoriesbyReview";
export const getTicketsByCategoryAPI =
  "https://xn4ait34q9.execute-api.us-east-2.amazonaws.com/dev/api/tickets/getticketsbycategory/";
export const getTicketMessagesAPI =
  "https://xn4ait34q9.execute-api.us-east-2.amazonaws.com/dev/api/ticketmessage/getmsg/";
export const getSendMessageAPI =
  "https://xn4ait34q9.execute-api.us-east-2.amazonaws.com/dev/api/ticketmessage/sendmsg/";
export const getAssignTicketsAPI =
  "https://xn4ait34q9.execute-api.us-east-2.amazonaws.com/dev/api/ticketmessage/assignticket/";
export const getCloseTicketsAPI =
  "https://xn4ait34q9.execute-api.us-east-2.amazonaws.com/dev/api/tickets/closeTicket/";
export const getPendingTicketsAPI =
  "https://xn4ait34q9.execute-api.us-east-2.amazonaws.com/dev/api/tickets/changestate/";
export const TransferTicketsAPI =
  "https://xn4ait34q9.execute-api.us-east-2.amazonaws.com/dev/api/tickets/changecategory/";
export const getSearchedTicketsAPI =
  "https://xn4ait34q9.execute-api.us-east-2.amazonaws.com/dev/api/tickets/searchtickets/";
export const getTypesByCategoryAPI =
  "https://xn4ait34q9.execute-api.us-east-2.amazonaws.com/dev/api/type/";
export const getAllCategoriesTickets =
  "https://xn4ait34q9.execute-api.us-east-2.amazonaws.com/dev/api/category/";

// USED IN "Users"
export const fetchNewUserAPI =
  "https://ogey57noad.execute-api.us-east-2.amazonaws.com/dev/api/authentication/administration/register/";
export const fetchEditUserAPI =
  "https://ogey57noad.execute-api.us-east-2.amazonaws.com/dev/api/authentication/administration/update/";
export const deleteUsuarioAPI =
  "https://ogey57noad.execute-api.us-east-2.amazonaws.com/dev/api/authentication/administration/deleteuser/";
export const fetchUsuariosByNeighbourhoodAPI =
  "https://ogey57noad.execute-api.us-east-2.amazonaws.com/dev/api/authentication/administration/getadminusersbyneighbourhood/";
export const getUsersAPI =
  "https://63luqij71h.execute-api.us-east-2.amazonaws.com/dev/api/person/searchPropietary";

// USED IN "Clasificados"
export const fetchProductsAPI =
  "https://zsyf5w72g9.execute-api.us-east-2.amazonaws.com/dev/api/shopItem/search-admin";
export const fetchDetailProductAPI =
  "https://zsyf5w72g9.execute-api.us-east-2.amazonaws.com/dev/api/shopItem/";
export const fetchDeleteProductAPI =
  "https://zsyf5w72g9.execute-api.us-east-2.amazonaws.com/dev/api/shopItem/state/inactive";
export const fetchAddProductAPI =
  "https://zsyf5w72g9.execute-api.us-east-2.amazonaws.com/dev/api/shopItem";
export const fetchEditProductAPI =
  "https://zsyf5w72g9.execute-api.us-east-2.amazonaws.com/dev/api/shopItem";
export const fetchVentasAPI =
  "https://zsyf5w72g9.execute-api.us-east-2.amazonaws.com/dev/api/order/sold";
export const fetchOrderQRAPI =
  "https://zsyf5w72g9.execute-api.us-east-2.amazonaws.com/dev/api/order/delivery-label/";
export const fetchDespachosAPI =
  "https://zsyf5w72g9.execute-api.us-east-2.amazonaws.com/dev/api/order/in-delivery-process";
export const fetchGetReadyToDeliverAPI =
  "https://zsyf5w72g9.execute-api.us-east-2.amazonaws.com/dev/api/order/in-delivery-process/";
export const fetchUploadProductsFileAPI =
  "https://zsyf5w72g9.execute-api.us-east-2.amazonaws.com/dev/api/shopItem/import";

export const fetchConversationsAPI =
  "https://mr7d0t06z6.execute-api.us-east-2.amazonaws.com/dev/api/conversation/getconversations/all";
export const sendMsgConversationAPI =
  "https://mr7d0t06z6.execute-api.us-east-2.amazonaws.com/dev/api/message/sendmsg";
export const getMsgConversationAPI =
  "https://mr7d0t06z6.execute-api.us-east-2.amazonaws.com/dev/api/message/getmsg/";
export const newConversationAPI =
  "https://mr7d0t06z6.execute-api.us-east-2.amazonaws.com/dev/api/conversation/startconversation/shops";
export const disclaimerMetodoPago =
  "https://63luqij71h.execute-api.us-east-2.amazonaws.com/dev/api/sys_param?code=MedioPagoTCLeyenda";

// hay que pedir a diseño el tema de categorias padres e hijos
// por ahora solamente vamos a dar de alta productos asi que hardcodeamos la categoria 1
export const fetchCategoriesAPIPadre =
  "https://zsyf5w72g9.execute-api.us-east-2.amazonaws.com/dev/api/ShopItemCategory/parents";
export const fetchCategoriesAPI =
  "https://zsyf5w72g9.execute-api.us-east-2.amazonaws.com/dev/api/ShopItemCategory/children/1";
export const fetchItemConditionsAPI =
  "https://zsyf5w72g9.execute-api.us-east-2.amazonaws.com/dev/api/ShopItemCondition/0/20";
export const fetchShippingMethodsAPI =
  "https://zsyf5w72g9.execute-api.us-east-2.amazonaws.com/dev/api/DeliveryMethod/0/20";
export const fetchPaymentMethodsAPI =
  "https://pw5rezsgyj.execute-api.us-east-2.amazonaws.com/dev/api/transaction-payment-method/shop";

// Reportes
export const fetchReporteMascotasAPI =
  "https://63luqij71h.execute-api.us-east-2.amazonaws.com/dev/api/pet/report";
export const fetchReporteTicketsAPI =
  "https://xn4ait34q9.execute-api.us-east-2.amazonaws.com/dev/api/tickets/report";
export const fetchReporteReservasAPI =
  "https://1an8i0xmil.execute-api.us-east-2.amazonaws.com/Staging/api/booking/report";
export const fetchUserPropertyAPI =
  "https://63luqij71h.execute-api.us-east-2.amazonaws.com/dev/api/masterdata/functional-units/users-by-property-report";
export const fetchReporteExpensasAPI =
  "https://pw5rezsgyj.execute-api.us-east-2.amazonaws.com/dev/api/expense/paid-resume";
export const fetchReporteClientsByNeighbourhoodAPI =
  "https://pw5rezsgyj.execute-api.us-east-2.amazonaws.com/dev/api/client/clients-data-by-neighbourhood-parent-report";
export const fetchReporteBilleterasPorBarrioAPI =
  "https://pw5rezsgyj.execute-api.us-east-2.amazonaws.com/dev/api/transaction/transaction-resume";
export const dogReportsAPI =
  "https://pw5rezsgyj.execute-api.us-east-2.amazonaws.com/dev/api/reports";

// Dashboard
export const fetchDashBilletera =
  "https://pw5rezsgyj.execute-api.us-east-2.amazonaws.com/dev/api/dashboard";
export const fetchDashPersona =
  "https://63luqij71h.execute-api.us-east-2.amazonaws.com/dev/api/dashboard";

//PaymentGateway

const paymentGatewayBaseUrl = "https://43naw9gjpa.execute-api.us-east-2.amazonaws.com/dev/api";
//const paymentGatewayBaseUrl = "http://localhost:51348/api";
export const paymentClientsAPI = `${paymentGatewayBaseUrl}/paymentgateway/client`;
export const paymentOperationsAPI = `${paymentGatewayBaseUrl}/paymentgateway/payment`;
export const merchantNumbersAPI = `${paymentGatewayBaseUrl}/paymentgateway/merchant_numbers`;
export const vendorsAPI = `${paymentGatewayBaseUrl}/paymentgateway/vendors`;
export const fetchPaymentOperationsDetailAPI = `${paymentGatewayBaseUrl}/paymentgateway/payment/id`;
export const fetchPaymentOperationsAPI = `${paymentOperationsAPI}/get-all-payments`;
export const fetchPaymentClientsAPI = `${paymentGatewayBaseUrl}/paymentgateway/client/get-all-clients`;
export const fetchPaymentClientAPI = `${paymentGatewayBaseUrl}/paymentgateway/client/get-client`;
export const fetchNotificationRequestFormatAPI = `${paymentGatewayBaseUrl}/paymentgateway/notification_request_format`;
export const fetchBankAccountsAPI = `${paymentGatewayBaseUrl}/paymentgateway/client/bank-accounts`;
export const fetchPaymentStatesAPI = `${paymentGatewayBaseUrl}/paymentgateway/payment_states`;

export const getShopsAPI =
  "https://zsyf5w72g9.execute-api.us-east-2.amazonaws.com/dev/api/shopStore/get-all";

//Alerts

const alertsBaseUrl = " https://12o5f6kyg4.execute-api.us-east-2.amazonaws.com/dev/api";
// const alertsBaseUrl = "http://localhost:63956/api";
export const alertsAPI = `${alertsBaseUrl}/alerts`;
export const emergencyPhonesAPI = `${alertsBaseUrl}/emergencyphones`;
export const messagesAPI = `${alertsBaseUrl}/messages`;
export const alertMotivesAPI = `${alertsBaseUrl}/alertmotive`;
export const alertMotivesNeighbourhoodsAPI = `${alertsBaseUrl}/alertmotiveneighbourhoods`;
export const notificationTypesAPI = `${alertsBaseUrl}/notificationtypes`;

//Resolutions
const regulationBaseUrl = "https://7giutc6e1i.execute-api.us-east-2.amazonaws.com/dev/api";
// const regulationBaseUrl = 'http://localhost:55255/api';
export const fetchProcessesAPI = `${regulationBaseUrl}/processes`;
export const fetchProcessAPI = `${regulationBaseUrl}/process_details`;
export const fetchPresignedFileUrl = `${regulationBaseUrl}/processes/presigned-url`;

//Communitties
const communitiesBaseUrl = "https://u2pz4v3sj6.execute-api.us-east-2.amazonaws.com/Staging/api";
// const communitiesBaseUrl = "http://localhost:58106/api";
export const communitiesAPI = `${communitiesBaseUrl}/communities`;
export const communityStatesAPI = `${communitiesBaseUrl}/community_states`;

//Bookings
const bookingsBaseUrl = "https://n3b6fgpzic.execute-api.us-east-2.amazonaws.com/dev/api";
// const bookingsBaseUrl = "http://localhost:5000/api";
export const bookingsNeighbourhoodActivitiesAPI = `${bookingsBaseUrl}/neighbourhood_activities`;
export const bookingsNeighbourhoodSpacesAPI = `${bookingsBaseUrl}/neighbourhood_spaces`;
export const bookingsNeighbourhoodAPI = `${bookingsBaseUrl}/neighbourhood_bookings`;
export const bookingsAvailableSpacesAPI = `${bookingsBaseUrl}/available_spaces`;
export const bookingsActivitiesAPI = `${bookingsBaseUrl}/activities`;
export const bookingsAPI = `${bookingsBaseUrl}/bookings`;
export const schedulesAPI = `${bookingsBaseUrl}/schedules`;
export const bookingsActivityRulesAPI = `${bookingsBaseUrl}/activity_rules`;
export const ActivityRuleValuesAPI = `${bookingsBaseUrl}/activity_rule_values`;
