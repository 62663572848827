import React, { useState } from 'react';
import MaterialTable, { MTableBodyRow } from 'material-table';
import Checkbox from '@material-ui/core/Checkbox';
import TablePagination from '@material-ui/core/TablePagination';
import styled from 'styled-components';
import Button from './Button';
import OnCheckSection from './OnCheckSection'
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core';


const Table = styled.div`

`
//overflow-y: auto;

const theme = createMuiTheme({
  palette: {
    secondary: {
      main: '#30735f',
    },
  },

});
function ReceivedAlertsTable(props) {
  let paging = false;
  let selection = false;
  if (props.options) {
    if (props.options.paging) {
      paging = props.options.paging;
    }
    if(props.options.selection){
      selection =  props.options.selection;
    }
  }

  

  let serverSide = false;
  if (props.options) {
    if (props.options.serverSide) {
      serverSide = props.options.serverSide;
    }
  }


  const firstLoad = true;
  return (
    <Table id={'Ritz-table'}>
       <MuiThemeProvider theme={theme}>
      <MaterialTable style={{ 'flex': '1' }}

        columns={props.columns}
        data={props.data}
      
        isLoading={props.isLoading}
        options={{
          draggable: false,
          toolbar: false,
          paging: paging,
          selection: true,
          rowStyle: rowData => ({ backgroundColor: rowData.tableData.checked ? '#37b15933' : '',
         borderRadius: '6px',
            boxShadow: 'rgba(0, 0, 0, 0.05) 0px 2px 1px 0px', }),
          sorting: true,
          pageSize: props.pagination ? props.pagination.pageSize : 5,
          emptyRowsWhenPaging: false,
          debounceInterval: 1000,
          actionsColumnIndex: -1,
          headerStyle: {
            zIndex: 0,
            backgroundColor: '#f9f9fb',
            color: '#9b9b9b',
          },
         /* rowStyle: {
            backgroundColor: rowData rowData.tableData.checked ? '#37b15933' : '' ,
            borderRadius: '6px',
            boxShadow: 'rgba(0, 0, 0, 0.05) 0px 2px 1px 0px',
            
          }*/
        }}
       
        onChangeRowsPerPage={paging && serverSide ? props.onChangeRowsPerPage : null}
        onOrderChange={paging && serverSide ? props.onOrderChange : null}
        localization={{
          pagination: {
              labelDisplayedRows: '{from}-{to} de {count}'
          },
          toolbar: {
              nRowsSelected: '{0} fila(s)'
          },
          header: {
              actions: 'Acciones'
          },
          body: {
              emptyDataSourceMessage: 'No hay registros para mostrar',
              filterRow: {
                  filterTooltip: 'Filtros'
              }
          }
      }}

      onSelectionChange={props.onSelectionChange}

      /*  components={paging && serverSide ?
          ({
            Pagination: componentsProps => (
              <TablePagination
                {...componentsProps}
                rowsPerPageOptions={[5, 10, 20, 30]}
                page={props.pagination.page}
                rowsPerPage={props.pagination.pageSize}
                count={props.pagination.totalRow}
                onChangePage={props.pagination.onChangePage}
              />
            ),
          }) : null}*/
          actions={props.actions}
          
      />
     
  </MuiThemeProvider>

    </Table>
    
  );
}

export default ReceivedAlertsTable

